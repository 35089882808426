import styled from 'styled-components';
import * as variables from './variables.js';

export const StyledSelectBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  grid-template-rows: 1fr;
  ul.left{
    grid-column: 1;
  }
  ul.right{
    grid-column: 3;
  }
  ul{
    list-style-type: none;
    overflow-y: scroll;
    li{
      cursor: pointer;
    }
    li:hover{
      background-color: blue;
      color: white;
    }
  }
`