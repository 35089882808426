import React from 'react';
import styled from 'styled-components';
import * as variables from '../styles/variables.js';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: ${variables.DARK_RED};
  stroke-width: 2px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => props.checked ? variables.VERY_LIGHT_RED : 'white'}
  border-radius: 3px;
  border: 1px solid black;
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  }
`
const Checkbox = ({ name, checked, ...props }) => (
  <CheckboxContainer data-name={name} {...props}>
    <StyledCheckbox checked={checked} data-name={name}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default Checkbox;