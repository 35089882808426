import React from 'react';
import CalendarPage from './calendar_page.jsx';
import request from "superagent";
import debounce from "lodash.debounce";
import {toDateString, addDays, startOfWeek, parseDate} from './helpers.jsx';
import { buildHeaders} from './helpers/auth.jsx';
import history from "./history";
import Modal from 'react-modal';
import WeekModal from './week_modal.jsx';
import CoachDashboard from './coach_dashboard.jsx';
import Spinner from './helpers/spinner.jsx';
import {StyledCalendarTopRow, StyledTopRow} from './styles/calendar_week_styles.js';
import {StyledCalendarTable, LoadingOverlay} from './styles/calendar_grid_styles.js';
import styled from 'styled-components';
import update from 'immutability-helper';

let NUM_WEEKS=80;
let NUM_DAYS=(NUM_WEEKS-1)*7
let KEEP_DAYS=300
const queryString = require('query-string');

const YearObserverDiv = styled.div`
  position: fixed;
  z-index:-1;
  width: 100%;
  top: 40%;
  height: 80px;
`

class CalendarGrid extends React.PureComponent {  

  renderPage(page,i,isActive) {
    let expanded;
    return (
      // <CalendarPage key={week_data.date}
      // week_data={week_data}
      // expanded={expanded}
      // openModal={this.openModal}
      // closeModal={this.closeModal}
      // userData={this.props.userData}
      // appData={this.props.appData}
      // isActive={isActive}
      <CalendarPage key={i}
        page={page}
        deleteDay={this.deleteDay}
        openModal={this.openModal}
        closeModal={this.closeModal}
        userData={this.props.userData}
        pageUserData={this.state.user}
        appData={this.props.appData}
        observer={this.yearObserver}
        createAssignment={this.createAssignment}
      />
    )
  }
  
  setLogDayRelations(log_day, user_data){
    log_day.running_logs.forEach((running_log)=> {
      if(running_log.shoe_id){
        running_log.shoe = user_data.shoes_hash[running_log.shoe_id]
      }
      running_log.extras = running_log.extra_ids.map((extra_id) => {
        return(user_data.extras_hash[extra_id]);
      })
      running_log.activity = user_data.activities_hash[running_log.activity_id]
    });
  }

  createAssignment(assignment_id, date){
    console.log(assignment_id);
  //   if(this.state.isLoading){
  //     return;
  //   }
    console.log("fuck you")
    this.setState({isLoading: true})
    request('POST', this.props.appData.rootUrl+'/users/'+this.state.user.id+'/running_logs')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', buildHeaders())
      .send({template_id: assignment_id, date: date})
      .then((results_str) => {
        // Creates a massaged array of user data
        let results = JSON.parse(results_str.text);
        console.log(results)
        let newLogDay = results.log_day;
        console.log(newLogDay);
        this.percolateLogDayUpdate(newLogDay, {date: toDateString(startOfWeek(new Date(date)))});
        this.setState({isLoading: false})
      })
  }

  percolateLogWeekUpdate = (logWeek) => {
    let pages = this.state.pages;
    let index, dates;
    for (var i = 0; i < logWeek.log_days.length; i++){
      this.setLogDayRelations(logWeek.log_days[i], this.state.user);
    }
    for (var i = 0; i < pages.length; i++) {
      let page = pages[i];
      dates = page.dates;
      index = dates.findIndex(el => el.date === logWeek.date);
      break;
    }
    const newPages = update(pages, {
      [i]: {dates: {[index]: {$set: logWeek}}}
    });
    const newWeekModalData = update(this.state.weekModalData, {
      logWeekModal: {$set: logWeek}
    });
    this.setState({pages: newPages, weekModalData: newWeekModalData});
  }

  percolateLogDayUpdate = (logDay, logWeek) => {
    let pages = this.state.pages;
    let index;
    let jndex;
    let dates;
    this.setLogDayRelations(logDay, this.state.user);
    for (var i = 0; i < pages.length; i++) {
      let page = pages[i];
      dates = page.dates;
      index = dates.findIndex(el => el.date === logWeek.date);
      if(index >= 0){
        jndex = dates[index].log_days.findIndex(el => el.log_date === logDay.log_date);
        break;
      } 
      //i+=1; WHY?
    }
    const newPages = update(pages, {
      [i]: {dates: {[index]: {log_days: {[jndex]: {$set: logDay}}}}}
    })
    const newWeek = newPages[i].dates[index];
    const newWeekModalData = update(this.state.weekModalData, {
      logWeekModal: {$set: newWeek}
    });
    this.setState({pages: newPages, weekModalData: newWeekModalData});
  }

  deleteDay = (log_day) => {
    if(!window.confirm("Are you sure you want to delete this day?")){
      return null;
    }
    request("DELETE", this.props.appData.rootUrl+'/users/'+log_day.user_id+'/log_days/'+log_day.id)
      .set('Content-Type', 'application/json')
      .set('Authorization', buildHeaders())
      .set('Accept', 'application/json')
      .then((results_str) => {
        console.log(results_str);
        let index, jndex, dates;
        let pages = this.state.pages;
        for (var i = 0; i < pages.length; i++) {
          let page = pages[i];
          dates = page.dates;
          index = dates.findIndex(el => el.date === toDateString(startOfWeek(new Date(log_day.log_date))));
          if(index >= 0){
            jndex = dates[index].log_days.findIndex(el => el.log_date === log_day.log_date);
            break;
          } 
          //i+=1; WHY?
        }
        let blank_ld = {
          log_date: log_day.log_date, 
          user_id: log_day.user_id,
          id: null, 
          running_logs: [], 
          sleep: null,
          blurbs: null
        }
        pages = update(pages, {
          [i]: {dates: {[index]: {log_days: {[jndex]: {$set: blank_ld}}}}}
        })
        this.setState({pages: pages})
      })
      
  }
  editWeek = (week) => {
    if (!this.state.user.permissions.can_write_assignments && !this.state.user.permissions.can_write_running_logs){
      return null;
    }
    this.setState((oldState, oldProps) => {
      return({
        weekModalData: {
          editingDay: false,
          editingWeek: true,
          logDay: null,
          logWeekModal: week
        }
      })
    })
  }
  editDay = (day) => {
    console.log(this.state.user.permissions)
    if (!this.state.user.permissions.can_write_assignments && !this.state.user.permissions.can_write_running_logs){
      return null;
    }
    console.log("next")
    this.setState((oldState, oldProps) =>{
      return {
        weekModalData: {
          editingDay: true,
          editingWeek: false,
          logDay: JSON.parse(JSON.stringify(day)),
          logWeekModal: oldState.weekModalData.logWeekModal,
        }
      }
    });
  }

  exitEditDay = () => {
    this.setState((oldState, oldProps) => {
      return {
        weekModalData: {
          editingDay: false, 
          editingWeek: false,
          logDay: null, 
          logWeekModal: oldState.weekModalData.logWeekModal,
        }
      }
    });
  }
  openModal = (logWeek, logDay) => {
    let newState = {modalIsOpen: true}
    let weekModalData = {};
    weekModalData.logWeekModal = logWeek;
    if(logDay){
      if (!this.state.user.permissions.can_write_assignments && !this.state.user.permissions.can_write_running_logs){
        return null;
      }
      weekModalData.editingDay = true;
      weekModalData.logDay = JSON.parse(JSON.stringify(logDay));
    }
    newState.weekModalData = weekModalData;
    this.setState(newState);
  }

  getWeekIndex(date){
    for(var i = 0; i < this.state.pages.length; i++){
      var page = this.state.pages[i];
      var index = page.dates.findIndex(el => el.date === date);
      if(index >= 0){
        break;
      }
    }
    return [i, index];
  }
  changeWeek(dir) {
    if(!this.state.weekModalData.logWeekModal || this.state.isLoading){
      return null;
    }
    // for(var i = 0; i < this.state.pages.length; i++){
    //   var page = this.state.pages[i];
    //   var index = page.dates.findIndex(el => el.date === this.state.logWeekModal.date);
    //   if(index >= 0){
    //     break;
    //   }

    // }
    let [i, index] = this.getWeekIndex(this.state.weekModalData.logWeekModal.date);
    let page = this.state.pages[i];
    let newIndex = index+dir;
    if (newIndex < 0 || newIndex >= page.dates.length){
      let callback = () => {
        this.changeWeek(dir);
      }
      if(dir < 0){
        this.scrollUp(callback);
      }else{
        this.scrollDown(callback);
      }
      return null;
    }
    this.setState((oldState, oldProps) => {
      return {
        weekModalData: {
          editingDay: oldState.editingDay, 
          logDay: oldState.logDay, 
          logWeekModal: page.dates[newIndex],
        }
      }
    });
  }

  downWeek(event) {
    event.preventDefault()
    this.changeWeek(1)
  }

  upWeek(event) {
    event.preventDefault();
    this.changeWeek(-1)
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  disableScrolling = () => {
    this.setState({canScroll: false});
  }

  enableScrolling = () => {
    this.setState({canScroll: true});
  }

  setHeaderYear(entries, observer){
    //this.setState({headerYear: year})
    if(entries[0].isIntersecting){
      //console.log(entries[0].target.getAttribute("data-year"))
      let year = entries[0].target.getAttribute("data-year").split("-")[0];
      if(year != this.state.headerYear){
        this.setState({headerYear: year})
      }
      //document.getElementById("headerYear").textContent = entries[0].target.getAttribute("data-year")
    }
  }

  constructor(props) {
    super(props);
    this.initialLoad = true;
    this.currentDateStr = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).date
    if (this.currentDateStr){
      this.currentDate = parseDate(this.currentDateStr);
    }else{
      this.currentDate = new Date();
    }
    this.state = {
      pages: [],
      error: false,
      isLoading: false,
      user_id: this.props.match.params.id,
      user: null,
      modalIsOpen: false,
      headerYear: this.currentDate.getFullYear(),
      weekModalData: {},
    }
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.percolateLogDayUpdate = this.percolateLogDayUpdate.bind(this);
    this.percolateLogWeekUpdate = this.percolateLogWeekUpdate.bind(this);
    this.downWeek = this.downWeek.bind(this);
    this.upWeek = this.upWeek.bind(this);
    this.enableScrolling = this.enableScrolling.bind(this);
    this.disableScrolling = this.disableScrolling.bind(this);
    this.setHeaderYear = this.setHeaderYear.bind(this);
    this.editDay = this.editDay.bind(this);
    this.exitEditDay = this.exitEditDay.bind(this);
    this.createAssignment = this.createAssignment.bind(this);

    //scroll vars
    this.topRef = React.createRef();
    this.botRef = React.createRef();
    this.initialRef = React.createRef();
    this.selfRef = React.createRef();
    this.setTop = false;
    this.oldTop = null;
    this.setBot = false;
    this.oldbot = null;

    this.yearDivRef = React.createRef();
    this.yearObserver = new IntersectionObserver(this.setHeaderYear, {
      root: this.yearDivRef.current,
    });
    // Binds our scroll event handler
    window.onscroll = debounce(() => {
      const {
        loadWeeks,
        state: {
          error,
          isLoading,
          modalIsOpen
        },
      } = this;
      
      if (false && this.state.dates.length > 0){
        //todo
        let pos = (document.documentElement.scrollTop - window.innerHeight/2)/document.documentElement.offsetHeight
        let i = Math.trunc(this.state.dates.length*pos);
        if (i < 0){i=0;}
        history.push(this.props.location.pathname+"?date="+toDateString(this.state.dates[i].date));
      }
      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (error || isLoading || this.initialLoad || modalIsOpen) return;

      // Checks that the page has scrolled to the bottom
      if (
        window.innerHeight + document.documentElement.scrollTop
        > document.documentElement.offsetHeight - 250
      ) {
        this.scrollDown();
      }
      // Checks that the page has scrolled to the top
      if (
        document.documentElement.scrollTop < 250
      ){
        this.scrollUp();
      }
    }, 50);
  }

  firstActivePageIndex(prevState){
    var _state = prevState || this.state;
    return _state.pages.findIndex(page => {
      return page.isActive;
    })
  }

  lastActivePageIndex(prevState){
    var _state = prevState || this.state;
    var i = _state.pages.length - 1;
    while(i >= 0){
      var page = _state.pages[i];
      if(page.isActive){
        return i;
      }
      i-=1;
    }
  }

  lastActivePage(){
    return this.state.pages[this.lastActivePageIndex()]
  }
  firstActivePage(){
    return this.state.pages[this.firstActivePageIndex()]
  }

  scrollUp(callback){
    let targetDate = addDays(this.firstActivePage().dates[0].date, (NUM_DAYS+1)*-1);
    this.loadWeeks(targetDate, false, callback);
  }

  scrollDown(callback){
    // this.scrollOffset = document.documentElement.offsetHeight - window.innerHeight - document.documentElement.scrollTop;
    const dates = this.lastActivePage().dates
    var targetDate = addDays(dates[dates.length-1].date, 7);
    this.loadWeeks(targetDate, true, callback);
  }

  createPageFromDates(date_array){
    return {isActive: true, dates: date_array};
  }
  prependPage(date_array){
    var page = this.createPageFromDates(date_array);
    this.setState(prevState => {
      const i = this.firstActivePageIndex(prevState);
      if(i === 0 || typeof i === "undefined"){
        return {pages: [page, ...prevState.pages]}
      }else{
        //const new_pages = prevState.pages;
        //new_pages[i-1] = page;
        const new_pages = update(prevState.pages, {
          [i-1]: {$set: page}
        });
        return {pages: new_pages}
      }
    })
  }

  appendPage(date_array){
    var page = this.createPageFromDates(date_array);
    this.setState(prevState => {
      const i = this.lastActivePageIndex(prevState);
      if(typeof i === "undefined"){
        //let weekModalData = {logWeekModal: date_array[0], logDay: date_array[0].log_days[0], editingDay: true}
        return {pages: [page]}//, modalIsOpen: true, weekModalData: weekModalData}
      }else{
        //const new_pages = prevState.pages;
        //new_pages[i+1] = page;
        const new_pages = update(prevState.pages, {
          [i+1]: {$set: page}
        });
        return {pages: new_pages};
      }
    })
  }

  loadWeeks = (_first_date, append, callback) => {
    //_first_date = parseDate(toDateString(_first_date));
    this.setState({ isLoading: true })
    var first_date; 
    var requestUserData;
    if (this.initialLoad){
      first_date = startOfWeek(addDays(_first_date, NUM_DAYS/-2));
      requestUserData="user_info=true";
    }else{
      first_date = startOfWeek(addDays(_first_date, 0));
      requestUserData="user_info=false";
    }
    let stateCallback;
    if(typeof(callback) === "function"){
      stateCallback = () => {callback();}
    }
    return request
      .get(this.props.appData.rootUrl+'/users/'+this.state.user_id+'/log_weeks')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', buildHeaders())
      .query('date='+toDateString(first_date))
      .query('num_weeks='+NUM_WEEKS)
      .query(requestUserData)
      .then((results_str) => {
        // Creates a massaged array of user data
        let results = JSON.parse(results_str.text);
        let user_data;
        if(results.user){
          user_data = results.user;
          let activities = {};
          user_data.activities.forEach((activity) => {
            activities[activity.id] = activity;
          });
          user_data.activities_hash = activities;

          let extras = {};
          user_data.extras.forEach((extra) => {
            extras[extra.id] = extra;
          });
          user_data.extras_hash = extras;

          let shoes = {};
          user_data.shoes.forEach((shoe) => {
            shoes[shoe.id] = shoe;
          });
          user_data.shoes_hash = shoes;          
          this.setState({user: user_data});
        }
        user_data = user_data || this.state.user; 
        //this.setState({isLoading: false})
        //return;
        let nextDates = results.dates.map(log_week => {
          if (this.initialLoad && log_week.date === toDateString(startOfWeek(_first_date))){
            log_week.ref = this.initialRef;
          }
          //week_data.date = _d;
          //week_data.key = _d;
          //week_data.id = log_week.id;
          //week_data.log_days = log_week.log_days;
          log_week.log_days.forEach((ld) => {
            this.setLogDayRelations(ld, user_data);
          });
          return log_week;
        });
        if (append)
        {
          if (this.initialLoad)
          {
            nextDates[0].ref = this.topRef;
            nextDates[nextDates.length-1].ref = this.botRef;
          }
          //this.oldBot = this.state.dates[this.state.dates.length - 1];
          this.setBot = true;
          //let totalDates = [...this.state.dates, ...nextDates,]
          //if (totalDates.length > KEEP_DAYS)
          //{
          //   totalDates = totalDates.slice(totalDates.length-KEEP_DAYS,totalDates.length);
          //   totalDates[0].ref = this.topRef;
          //}
          //this.scrollOffset = document.documentElement.offsetHeight - window.innerHeight - document.documentElement.scrollTop;
          this.appendPage(nextDates);
          this.setState({
            isLoading: false,
          }, stateCallback);
        }else
        {
          //this.oldTop = this.state.dates[0];
          this.setTop = true;
          //let totalDates = [...nextDates, ...this.state.dates,]
          // if (totalDates.length > KEEP_DAYS)
          // {
          //   totalDates = totalDates.slice(0,KEEP_DAYS);
          //   totalDates[totalDates.length - 1].ref = this.botRef;
          // }
          this.prependPage(nextDates);
          this.setState({
            isLoading: false,
          }, stateCallback);
        }
      })
      .catch((err) => {
        this.setState({
          error: err.message,
          isLoading: false,
         });
        console.log(err);
      })
  }
  // componentWillReceiveProps(nextProps) {
  //   this.scrollOffset = document.documentElement.offsetHeight - window.innerHeight - document.documentElement.scrollTop;
  // }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
    if (prevState.pages.length < this.state.pages.length) {
      return document.documentElement.scrollHeight - document.documentElement.scrollTop;
    }
    return null;
  }

  componentDidMount(){
    this.loadWeeks(this.currentDate, true, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.setTop && !this.initialLoad){
      //window.scrollTo(0, this.topRef.current.offsetTop+document.documentElement.scrollTop-3.5);
      // this.oldTop.ref = null;
      // this.state.dates[0].ref = this.topRef;
      // this.setTop = false;
      if(snapshot !== null){
        this.setTop = false;
        document.documentElement.scrollTop = document.documentElement.scrollHeight - snapshot;
      }
    }else if (this.setBot && !this.initialLoad){
      //window.scrollTo(0, this.botRef.current.offsetTop-window.innerHeight-this.scrollOffset+this.botRef.current.clientHeight+3.5+this.selfRef.current.offsetTop);
      // this.oldBot.ref = null;
      // this.state.dates[this.state.dates.length - 1].ref = this.botRef;
      // this.setBot = false;
    }else if (this.initialLoad){
      if (this.initialRef.current)
      {
        window.scrollTo(0, this.initialRef.current.offsetTop-window.innerHeight/3)
        this.initialLoad = false;
        this.setBot = false;
        this.setTop = false;
      }
    }

  }
  // componentWillMount() {
  //   // Loads some users on initial load
  //   this.initialLoad = true;
  //   this.loadWeeks(this.currentDate, true, true);
  //   //window.scrollTo(0,500);
  // }

  render() {
    return (
      <>
        {this.state.isLoading && 
          <LoadingOverlay>
            <Spinner/>
          </LoadingOverlay>
        }
        <YearObserverDiv></YearObserverDiv>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          className={"biggerlogModal"}
          overlayClassName={"biggerlogModalOverlay"}
        >
          <WeekModal 
            closeModal={this.closeModal}
            logWeek={this.state.weekModalData.logWeekModal}
            editingDay={this.state.weekModalData.editingDay}
            editingWeek={this.state.weekModalData.editingWeek}
            logDay={this.state.weekModalData.logDay} 
            isLoggedIn={this.props.isLoggedIn}
            userData={this.props.userData}
            pageUserData={this.state.user}
            percolateLogDayUpdate={this.percolateLogDayUpdate}
            percolateLogWeekUpdate={this.percolateLogWeekUpdate}
            appData={this.props.appData}
            upWeek={this.upWeek}
            downWeek={this.downWeek}
            editDay={this.editDay}
            editWeek={this.editWeek}
            exitEditDay={this.exitEditDay}
          />
        </Modal>
        <StyledCalendarTopRow calculatedTdWidth={this.props.appData.cellWidth}>
          <StyledTopRow id="headerYear">
            {this.state.headerYear}
            <CalendarToolbar/>
          </StyledTopRow>
          <StyledTopRow>Monday</StyledTopRow>
          <StyledTopRow>Tuesday</StyledTopRow>
          <StyledTopRow>Wednesday</StyledTopRow>
          <StyledTopRow>Thursday</StyledTopRow>
          <StyledTopRow>Friday</StyledTopRow>
          <StyledTopRow>Saturday</StyledTopRow>
          <StyledTopRow>Sunday</StyledTopRow>
        </StyledCalendarTopRow>
        <StyledCalendarTable ref={this.selfRef}>
          {
            this.state.pages.map( (page, i) => {
              return this.renderPage(page, i, true);
            })
          }
        </StyledCalendarTable>
        {this.state.user && this.state.user.permissions.coach && 
          <CoachDashboard
            user={this.state.user}
            userData={this.props.userData}
            appData={this.props.appData}
          />
        }
      </>
    )
  }
}

class CalendarToolbar extends React.PureComponent{
  render() {
    return(null
    )
  }
}
export default CalendarGrid;