import React from 'react';
import { LocalizeProvider, withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { setActiveLanguage } from 'react-localize-redux';
//import globalTranslations from "./translations/global.json";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { isLoggedIn, logIn, logOut, buildHeaders} from './helpers/auth.jsx';
import CalendarGrid from './calendar_grid.jsx';
import CoachPage from './coach_page.jsx';
import calendarTranslations from "./translations/calendar.json";
import Modal from 'react-modal';
import TopNav from './top_nav.jsx'
import request from "superagent";
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

Modal.setAppElement('#root');

// wrapping/composing
// You can spread routeProps to make them available to your rendered Component
// const CalendarRoute = ({ component: CalendarGrid, ...rest }) => (
//   <Route {...rest} render={routeProps => (
//     <>
//       <CalendarGrid {...routeProps}/>
//     </>
//   )}/>
// )

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Nederlands", code: "nl" }
      ],
      options: {
        renderToStaticMarkup,
        defaultLanguage: "nl",
      }
      //translation: globalTranslations,
      //options: { renderToStaticMarkup }
    });
    this.props.addTranslation(calendarTranslations);
    setActiveLanguage("nl");

    this.rootUrl = process.env.NODE_ENV === 'production' ? "https://api.biggerlog.com" : "http://localhost:5000"
    //this.rootUrl = process.env.NODE_ENV === 'production' ? "http://localhost:5000" : "http://localhost:5000"
 
    this.appData={rootUrl: this.rootUrl, cellWidth: Math.round(window.innerWidth/7)-20};
    if(isLoggedIn()){
      this.state = {isLoggedIn: true}
      this.getUserState();
    }else{
      this.state = {isLoggedIn: false, userData: this._createFakeUserData()}
    }
    this._logIn = this._logIn.bind(this);
    this.getUserState = this.getUserState.bind(this);


  }

  getUserState = () => {
    return request
      .get(this.appData.rootUrl+'/users')
      .set('Content-Type', 'application/json')
      .set('Authorization', buildHeaders())
      .set('Accept', 'application/json')
      .then((results_str) => {
        let user_data = JSON.parse(results_str.text);
        let activities = {};
        user_data.activities.forEach((activity) => {
          activities[activity.id] = activity;
        });
        user_data.activities_hash = activities;
        this.setState({userData: user_data});
      })
  }

  _createFakeUserData(){
    return {activities: [], activities_hash: {}, mileage_unit: "mi"};
  }
  _logIn = (_state, jwt) => {
    if(_state && jwt !== null){
      logIn(jwt);
      this.getUserState();
      this.setState({isLoggedIn: _state});
    }else if(!_state){
      logOut();
      this.setState({isLoggedIn: _state, userData: this._createFakeUserData()});
    }
  }
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <LocalizeProvider>
          <Router>
            <div>
              <TopNav 
                isLoggedIn={this.state.isLoggedIn} 
                setAppLoggedIn={this._logIn}
                userData={this.state.userData}
                updateUserState={this.getUserState}
                appData={this.appData}
              />

               <Route 
                path="/users/:id" 
                render={(props) => <CalendarGrid 
                  isLoggedIn={this.state.isLoggedIn} 
                  userData={this.state.userData}
                  appData={this.appData}
                  {...props}/>} 
                currentDate={this.currentDate} 
                isLoggedIn={this.state.isLoggedIn}
                userData={this.state.userData}
                />

              <Route 
                path="/coach" 
                render={(props) => <CoachPage 
                  isLoggedIn={this.state.isLoggedIn} 
                  userData={this.state.userData}
                  appData={this.appData}
                  {...props}/>} 
                currentDate={this.currentDate} 
                isLoggedIn={this.state.isLoggedIn}
                userData={this.state.userData}
                />
            </div>
          </Router>
        </LocalizeProvider>
      </DndProvider>
    )
  }
}

export default withLocalize(App);