import React from 'react';
import {toDateRange, roundIt, toDateString} from './helpers.jsx';
import LogDay from './log_day.jsx';
import {StyledCalendarRow, StyledCalendarWeekStartDiv} 
  from './styles/calendar_week_styles.js';
const CONVERSIONS = {"m": {"m": 1, "km": 0.001, "mi": 0.0006213712}, 
                    "mi": {"mi": 1, "km": 1.609344, "m": 1609.344},
                    "km": {"km": 1, "mi": 0.6213712, "m": 1000.0}}


const dateRange = (logWeek) => {

}
class CalendarWeek extends React.PureComponent {
  constructor(props) {
    super(props);
    this.clickWeek  = this.clickWeek.bind(this);
    this.mouseOver  = this.mouseOver.bind(this);
    this.mouseOut   = this.mouseOut.bind(this);
    this.onShow     = this.onShow.bind(this);
    this.onShowIdle = this.onShowIdle.bind(this);
    
    this.state = {hovering: false};
    this.observerRef = React.createRef();
  }

  dateRange = () => {
    return toDateRange(this.props.week_data.date);
  }

  onShow(entries, observer){
    if(entries[0].isIntersecting){
      //console.log(this.props.week_data.date)
      //this.props.setHeaderYear(toDateString(this.props.week_data.date))
    }
    window.requestIdleCallback(this.onShowIdle)
  }

  onShowIdle(entries, observer){
    this.props.setHeaderYear(toDateString(this.props.week_data.date))
    //console.log("foo")
    // if(entries[0].isIntersecting){
    //   console.log(this.props.week_data.date)
    //   this.props.setHeaderYear(this.props.week_data.date.getFullYear())
    // }
  }

  componentDidMount(){
    // let observerOptions = {
    //   root: null,
    //   rootMargin: '0px',
    //   threshold: 0.5
    // }
    // let observer = new IntersectionObserver(this.onShow);
    this.props.observer.observe(this.observerRef.current)
  }
  render () {
    if(this.props.isActive){
      return (
        
        <StyledCalendarRow 
          onClick={this.clickWeek} 
          onMouseOver={this.mouseOver} 
          onMouseOut={this.mouseOut}
          calculatedTdWidth={this.props.appData.cellWidth}
          ref={this.observerRef}
          canDisplay={this.props.canDisplay}
          data-year={this.props.week_data.date}
        > 
          <StyledCalendarWeekStartDiv ref={this.props.week_data.ref}>
            <span>
              {this.dateRange()}
            </span>
            <span>
              {weekSummary(this.props.week_data, this.props.userData.mileage_unit)}
            </span>
          </StyledCalendarWeekStartDiv>
          {this.props.week_data.log_days.map(log_day => (
            <LogDay 
              log_day={log_day} 
              key={log_day.log_date}
              userData={this.props.userData}
              hovering={this.state.hovering}
              pageUserData={this.props.pageUserData}
              editDay={this.editDay}
              deleteDay={this.props.deleteDay}
              createAssignment={this.props.createAssignment}
            />
          ))}
        </StyledCalendarRow>

      )
    }else{
      return(<tr></tr>)
    }
  }

  editDay = (log_day_json) => {
    this.props.openModal(this.props.week_data, log_day_json)
  }
  clickWeek = (event) => {
    event.preventDefault();
    this.props.openModal(this.props.week_data);
  }

  mouseOver = () => {
    this.setState({hovering: true});
  }

  mouseOut = () => {
    this.setState({hovering: false});
  }
}

const convertUnit = (distance, distance_unit, new_distance_unit) => {
  return CONVERSIONS[distance_unit][new_distance_unit] * distance;
}

export const weekSummary = (logWeek, targetUnit) => {
  let summary = {Running: 0};
  if (logWeek.log_days === null) {
    return null;
  }
  logWeek.log_days.forEach(log_day => {
    log_day.running_logs.forEach(rl => {
      if (rl.distance !== 0 && rl.distance !== null && !rl.is_assignment){
        if(rl.activity.running){
          summary.Running += convertUnit(rl.distance, rl.distance_unit, targetUnit);
        }else{
          let key = rl.activity.name;
          if(summary.hasOwnProperty(key)){
            summary[key] += convertUnit(rl.distance, rl.distance_unit, targetUnit);
          }else{
            summary[key] = convertUnit(rl.distance, rl.distance_unit, targetUnit)
          }
        }
      }
    });
  });
  Object.keys(summary).forEach((k) => {
    summary[k] = roundIt(summary[k], 2);
  })
  const divs = Object.keys(summary).map((key) => {
    let val = summary[key];
    return(
      <div key={key}>
        {key}: {summary[key]} {targetUnit}
      </div>
    )
  })
  return(
    <>
      {divs}
    </>
  )
}
export default CalendarWeek;