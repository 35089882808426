import React from 'react';
import styled from 'styled-components';
import * as variables from '../styles/variables.js';

export const ButtonStyled = styled.span`
  background-color: ${variables.LIGHTER_DARK_RED};
  color: black;
  padding: 10px 5px 10px 5px;
  border-radius: 4px;
  text-align: center;
  &:hover{
    background-color: ${props => props.disabled ? variables.VERY_LIGHT_RED : variables.DARK_RED};
    color: ${props => props.disabled ? variables.DARK_RED : 'white'};
  }
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`
const Button = ({disabled, ...props }) => (
  <ButtonStyled {...props}>
    <b>{props.children}</b>
  </ButtonStyled>
)

export default Button;