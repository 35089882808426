import styled from 'styled-components';
import * as variables from './variables.js';

export const LogDayDateStyled = styled.div`
  display: inline-block;
  background-color: black;
  color: white;
  padding: 0 2px 0px 2px;
  border-radius: 2px;
  float: right;
`
export const CalendarEditSpan = styled.span`
  float: right;
  visibility: ${props => props.hovering ? "visible" : "hidden"};
`

export const CalendarDeleteSpan = styled(CalendarEditSpan)`

`
export const StyledRunningLog = styled.div`
  background-color: ${props => props.backgroundColor ? props.backgroundColor : "#E6E6E6"};
  //text-align: center;
  border: ${props => props.isAssignment ? '2px solid red' : '1px solid black'};
  margin: 1px 1px 3px 1px;
  border-radius: 2px;
  padding: 2px;
  text-overflow: ellipsis;
`