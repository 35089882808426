var jwtDecode = require('jwt-decode');

export const logIn = (jwt) => {
  localStorage.setItem('authToken', jwt);
}

export const logOut = () => {
  localStorage.setItem('authToken', null);
}

export const isLoggedIn = () => {
  const res = buildHeaders()
  if (res === null){
    return false;
  }else{
    return true;
  }
}

export const buildHeaders = () => {
  const token = localStorage.getItem('authToken')
  if (token === null){
    return null;
  }
  let decoded;
  try{
    decoded = jwtDecode(token);
  }catch(err){
    return null;
  }
  if (decoded.exp*1000 < (new Date()).getTime()){
    return null;
  }
  return("Bearer " +token)
}