import React from 'react';
import Modal from 'react-modal';
import request from "superagent";
import {buildHeaders} from './helpers/auth.jsx';
import EditButton from './helpers/edit_button.jsx';
import Checkbox from './helpers/checkbox.jsx';
import Button from './helpers/button.jsx';
import SketchExample from './my_color_picker.jsx';
import {StyledActivityDiv, 
  StyledActivityHolder, 
  StyledActivityForm,
  StyledExtraDiv,
  StyledEditButton,
  StyledRetireButton,
  StyledActivateButton,
  StyledShoeHolder,
  ShowShoes,
  StyledShoeForm} from './styles/top_nav_styles.js';

import {
  useHistory
} from "react-router-dom";

import { useDrag } from 'react-dnd';
import DragTypes from './helpers/drag_types.js';
import DraggableList from './helpers/draggable_list.jsx';
import {convertUnit} from './helpers.jsx';

import update from 'immutability-helper';

function LoginButton(props) {
  return (
    <p 
      className="biggerlogButton"
      onClick={props.clickFun}
    >      
      Sign in
    </p>
  )
}

function ShoesButton(props){
  return (
    <p 
      className="biggerlogButton"
      onClick={props.clickFun}
    >      
      Shoes
    </p>
  ) 
}
function ActivitiesButton(props) {
  return (
    <p 
      className="biggerlogButton"
      onClick={props.clickFun}
    >      
      Activities
    </p>
  ) 
}

function LogoutButton(props){
  return(
    <p 
      className="biggerlogButton"
      onClick={props.clickFun}
    >      
      Sign out
    </p>
  )
}

function CoachPlanButton(props){
  let history = useHistory();
  function handleClick() {
    history.push("/coach");
  }
  return(
    <p className="biggerlogButton" onClick={handleClick}>
      Coach
    </p>

    )
}

function calculateShoeMileage(shoe, mileage_unit){
  return convertUnit(parseInt(shoe.mileage.split(" ")[0]), "m", mileage_unit);
}
function Shoe({style, className, item, useRef, mileage_unit, ...props}){
  let shoe = item;
  return(
    <div
      style={style}
      className={className}
      ref={useRef}
    >
      <div>
        <span>{shoe.name}</span>
      </div>
      <div className="mileage">
        {calculateShoeMileage(shoe, mileage_unit)} {mileage_unit}
      </div>
      <StyledRetireButton className={shoe.retired ? "runnerlog-orange-button" : "runnerlog-blue-button"} >
        <a onClick={(event) => {
              props.retireShoe(shoe);
            }
          }
        >
          {shoe.retired && 
            'Activate'
          }
          {!shoe.retired && 
            'Retire'
          }
        </a>
      </StyledRetireButton>
      <div className="deleteShoe runnerlog-red-button">
        <a onClick={(event) => {
                props.deleteShoe(shoe)
                }
            }
        >
          X
        </a>
      </div>
    </div>
  )
}
class ShoesModal extends React.PureComponent{

  setUpShoes(shoes) {
    let shoes_not_retired = shoes.filter(shoe => !shoe.retired);
    let shoes_retired = shoes.filter(shoe => shoe.retired);
    shoes_not_retired.sort((shoe_a, shoe_b) => (shoe_a.position-shoe_b.position));
    this.setState({shoes_retired: shoes_retired, shoes_not_retired});
  }

  // static getDerivedStateFromProps(nextProps, prevState){
  //   console.log("boo");
  //   let shoes = nextProps.shoes;
  //   let shoes_not_retired = shoes.filter(shoe => !shoe.retired);
  //   let shoes_retired = shoes.filter(shoe => shoe.retired);
  //   shoes_not_retired.sort((shoe_a, shoe_b) => (shoe_a.position-shoe_b.position));
  //   return {shoes_retired: shoes_retired, shoes_not_retired}; 
  // }

  // componentDidMount(){
  //   this.setUpShoes(this.props.shoes);
  // }

  constructor(props){
    super(props);
    let shoes_not_retired = props.shoes.filter(shoe => !shoe.retired);
    let shoes_retired = props.shoes.filter(shoe => shoe.retired);
    shoes_not_retired.sort((shoe_a, shoe_b) => (shoe_a.position-shoe_b.position));
    this.state = ({
      error: null, 
      activeShoe: {id: null, name: "", mileage: 0},
      showRetired: false,
      submitting: false,
      shoes_retired: shoes_retired,
      shoes_not_retired: shoes_not_retired,
    })
    // this.shoes_not_retired = memoize(shoes => shoes.filter(shoe => !shoe.retired));
    // this.shoes_retired = memoize(shoes => shoes.filter(shoe => shoe.retired));
    this.changeShoeValue = this.changeShoeValue.bind(this);
    this.submit = this.submit.bind(this);
    this.editShoe = this.editShoe.bind(this);
    this.newShoe = this.newShoe.bind(this);
    this.showShoes = this.showShoes.bind(this);
    this.retireShoe = this.retireShoe.bind(this);
    this.moveShoe = this.moveShoe.bind(this);
    this.deleteShoe = this.deleteShoe.bind(this);
  }

  showShoes(){
    this.setState((oldState, oldProps) => ({
      showRetired: !oldState.showRetired
    }));

  }
  newShoe(){
    const shoe = {id: null, name: ""}
    this.setState({activeShoe: shoe});
  }

  editShoe(shoe_id){
    this.setState({activeShoe: JSON.parse(JSON.stringify(this.props.shoes_hash[shoe_id]))})
  }

  moveShoe(newShoes, oldIndex, newIndex){
    let orderJson = newShoes.map((shoe, i) => {
      return {id: shoe.id, position: i+1, old_position: shoe.position}
    });
    if(orderJson.reduce(((tot, _j) => (tot && _j.position === _j.old_position)),true))
      return;
    
    let req = request;
    req = request.post(this.props.appData.rootUrl+'/shoes/order')
    return req
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', buildHeaders())
      .send({new_orders: orderJson})
      .then((results_str) => {
        let json = JSON.parse(results_str.text).shoes;
        this.setState({shoes_not_retired: json});
        this.props.updateUserState();
      })
       .catch((err) => {
        console.log(err.response)
        let error_json = JSON.parse(err.response.text).errors;
        let error_array = Object.keys(error_json).map((key) => {
          return error_json[key].map((message) =>{
            return(key+": "+message);
          })
        })
        console.log(error_array)
        this.setState({error: error_array});
      })
  }

  deleteShoe(shoe){
    const confirm_msg = "Are you sure you want to delete this shoe? ("+shoe.id+")";
    if(!window.confirm(confirm_msg)){
      return false;
    }
    let req = request;
    req = request.delete(this.props.appData.rootUrl+'/shoes/'+shoe.id)
    return req
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', buildHeaders())
      .then((results_str) => {
        this.props.updateUserState();
        this.setUpShoes(JSON.parse(results_str.text).shoes)
        var arr_name;
        if(shoe.retired){
          arr_name = "shoes_retired";
        }else{
          arr_name = "shoes_not_retired";
        }
        const arr = this.state[arr_name];
        const _ix = arr.findIndex(_s => shoe.id === _s.id)
        const new_arr = update(arr, {
                                $splice: [
                                  [_ix, 1]
                                ],
                              });
        this.setState({[arr_name]: new_arr})
      })
       .catch((err) => {
        console.log(err.response)
        let error_json = JSON.parse(err.response.text).errors;
        let error_array = Object.keys(error_json).map((key) => {
          return error_json[key].map((message) =>{
            return(key+": "+message);
          })
        })
        console.log(error_array)
        this.setState({error: error_array});
      })
  }
  retireShoe(shoe){
    let req = request;
    req = request.put(this.props.appData.rootUrl+'/shoes/'+shoe.id)
    shoe.retired = !shoe.retired;
    return req
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', buildHeaders())
      .send({shoe: shoe})
      .then((results_str) => {
        this.props.updateUserState();
        this.setUpShoes(JSON.parse(results_str.text).shoes)
      })
       .catch((err) => {
        console.log(err.response)
        let error_json = JSON.parse(err.response.text).errors;
        let error_array = Object.keys(error_json).map((key) => {
          return error_json[key].map((message) =>{
            return(key+": "+message);
          })
        })
        console.log(error_array)
        this.setState({error: error_array});
      })
  }

  changeShoeValue(col, val){
    this.setState((oldState)=>{
      const shoe = oldState.activeShoe;
      const newShoe = update(shoe, {[col]: {$set: val}});
      return({activeShoe: newShoe});
    });
  }

  submit(event){
    event.preventDefault();
    this.setState({submitting: true})
    let shoe = this.state.activeShoe;
    let req = request;
    if(shoe.id){
      req = request.patch(this.props.appData.rootUrl+'/shoes/'+shoe.id)
    }else{
      req = request.post(this.props.appData.rootUrl+'/shoes/');
    }
    return req
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', buildHeaders())
      .send({shoe: shoe})
      .then((results_str) => {
        this.props.updateUserState();
        this.setState({activeShoe: {mileage: 0, name: "", id: null}, submitting: false, error: null,})
        this.setUpShoes(JSON.parse(results_str.text).shoes)
      })
       .catch((err) => {
        console.log(err.response)
        let error_json = JSON.parse(err.response.text).errors;
        let error_array = Object.keys(error_json).map((key) => {
          return error_json[key].map((message) =>{
            return(key+": "+message);
          })
        })
        console.log(error_array)
        this.setState({error: error_array});
      })

  }
  render(){
    let activeShoe = this.state.activeShoe;
    
    let shoe_divs_draggable =(
          <DraggableList
            items={this.state.shoes_not_retired}
            mileage_unit={this.props.mileage_unit}
            retireShoe={this.retireShoe}
            deleteShoe={this.deleteShoe}
            className="entry"
            setItems={(newShoes) => {this.setState({shoes_not_retired: newShoes})}}
            finishMoveItem={this.moveShoe}
            RenderItem={Shoe}
            item_type={DragTypes.SHOE}
            style={{cursor: 'pointer'}}
          />
        )
    
    let shoe_divs_retired = this.state.shoes_retired.map((shoe) => {
      return(
        <Shoe 
          item={shoe} 
          mileage_unit={this.props.mileage_unit} 
          retireShoe={this.retireShoe}
          deleteShoe={this.deleteShoe}
          key={shoe.id}
          className="entry"/>
      )
    });
    return(
      <>
        <StyledShoeHolder>
          <div className="shoe-holder">
            <div className="entry header">
              <div><b>Name</b></div>
              <div><b>Mileage</b></div>
              <div><b>Retired</b>?</div>
              <div/>
            </div>
            {shoe_divs_draggable}
            {this.state.showRetired && shoe_divs_retired}
          </div>
        </StyledShoeHolder>
        { !this.state.showRetired &&
            <StyledShoeForm onSubmit={this.submit}>
              <p>New Shoe</p>
              <div/>
              <div className="errors">
                {this.state.error}
              </div>
              <div/>
              <label>
                Name:
              </label> 
              <input 
                type={'text'} 
                value={activeShoe.name}
                onChange={(event) => {
                  this.changeShoeValue('name', event.target.value)}}
              />
              <label>
                Mileage: (0 if new shoe)
              </label>
              <input 
                type={'text'} 
                value={activeShoe.mileage}
                onChange={(event) => {
                  this.changeShoeValue('mileage', event.target.value)}}
              />
              <input type="submit" value="Save" disabled={this.state.submitting}/>
            </StyledShoeForm>
          }
        <ShowShoes className="show-shoes" onClick={this.showShoes} show={this.state.showRetired}>
          {this.state.showRetired && '← Create Shoe'}
          {!this.state.showRetired && 'More →'}
        </ShowShoes>
      </>

    )
  }
}
class ActivitiesModal extends React.PureComponent{
  constructor(props){
    super(props);
    this.state = ({
      error: null, 
      activeActivity: null,
      activeExtra: null,
    })
    this.changeActivityValue = this.changeActivityValue.bind(this);
    this.changeActivityColor = this.changeActivityColor.bind(this);
    this.submit = this.submit.bind(this);
    this.editActivity = this.editActivity.bind(this);
    this.clickRunningCheckbox = this.clickRunningCheckbox.bind(this);
    this.clickSharedCheckbox = this.clickSharedCheckbox.bind(this);
    this.newActivity = this.newActivity.bind(this);
    this.newExtra = this.newExtra.bind(this);
    this.editExtra = this.editExtra.bind(this);
  }

  changeActivityValue(event){
    event.preventDefault();
    const value = event.target.value;
    const name = event.target.name
    this.setState((oldState)=>{
      const act = oldState.activeActivity;
      //act[name] = value;
      const new_act = update(act, {[name]: {$set: value}}); // ['x', 'y']
      return({activeActivity: new_act});
    });
  }

  changeActivityColor(color){
    this.setState((oldState)=>{
      const act = oldState.activeActivity;
      const new_act = update(act, {color_code: {$set: color}});
      return({activeActivity: new_act});
    });
  }

  clickRunningCheckbox(){
    this.setState((oldState)=>{
      const act = oldState.activeActivity;
      const new_act = update(act, {running: {$set: !act.running}});
      return({activeActivity: new_act});
    });
  }

  clickSharedCheckbox(){
    this.setState((oldState)=>{
      const act = oldState.activeActivity;
      const new_act = update(act, {shared: {$set: !act.shared}});
      return({activeActivity: new_act});
    });
  }

  newExtra(){
    console.log("hell")
    const extra = {type: "extra", id: null, name: ""}
    this.setState({activeActivity: extra})
  }
  newActivity(){
    const act = {type: "activity", id: null, name: "", color_code: "#E6E6E6", running: true, shared: false, user_id: this.props.userData.id}
    this.setState({activeActivity: act});
  }

  editActivity(activity_id){
    const activity = this.props.activities_hash[activity_id];
    activity.type = "activity";
    this.setState({activeActivity: activity})
  }

  editExtra(extra_id){
    let extra = this.props.extras.find(_e => _e.id === extra_id)
    extra.type = "extra"
    this.setState({activeActivity: extra})
  }

  submit_extra(){
    let extra = this.state.activeActivity;
    let req = request;
    if(extra.id){
      req = request.patch(this.props.appData.rootUrl+'/extras/'+extra.id)
    }else{
      req = request.post(this.props.appData.rootUrl+'/extras/');
    }
    return req
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', buildHeaders())
      .send({extra: extra})
      .then((results_str) => {
        this.props.updateUserState();
        this.setState({activeActivity: null, error: null})
      })
       .catch((err) => {
        console.log(err.response)
        let error_json = JSON.parse(err.response.text).errors;
        let error_array = Object.keys(error_json).map((key) => {
          return error_json[key].map((message) =>{
            return(key+": "+message);
          })
        })
        console.log(error_array)
        this.setState({error: error_array});
      })
  }
  submit(event){
    event.preventDefault();
    let activity = this.state.activeActivity;
    if(activity.type === "extra"){
      return this.submit_extra();
    }
    let req = request;
    if(activity.id){
      req = request.patch(this.props.appData.rootUrl+'/activities/'+activity.id)
    }else{
      req = request.post(this.props.appData.rootUrl+'/activities/');
    }
    return req
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', buildHeaders())
      .send({activity: activity})
      .then((results_str) => {
        this.props.updateUserState();
        this.setState({activeActivity: null, error: null})
      })
       .catch((err) => {
        console.log(err.response)
        let error_json = JSON.parse(err.response.text).errors;
        let error_array = Object.keys(error_json).map((key) => {
          return error_json[key].map((message) =>{
            return(key+": "+message);
          })
        })
        console.log(error_array)
        this.setState({error: error_array});
      })
  }
 
  render(){
    let activities = this.props.activities;
    //let activity_out = [];
    //let activity = activities_edit[this.state.activeActivityId];
    let activeActivity = this.state.activeActivity;
    let activity_divs = activities.map((activity) => {
      return (
        <ActivityDiv 
          activity={activity} 
          key={activity.id}
          editActivity={this.editActivity}
          userData={this.props.userData}
        />
      )
    });

    let extras = this.props.extras;
    let activeExtra = this.state.activeExtra;
    let extra_divs = extras.map((extra)=> (
      <ExtraDiv
        extra={extra}
        key={extra.id}
        editExtra={this.editExtra}
        userData={this.props.userData}
      />
    ))
    return(
      <>
        <StyledActivityHolder>
          <div className="left">
            <Button className="new" onClick={this.newActivity}>
              New
            </Button>
            <div/>

            <div className="list-holder">
              {activity_divs}
            </div>
          </div>
          <div className="right">
            <Button className="new" onClick={this.newExtra}>
              New
            </Button>
            <div/>
            <div className="list-holder">
              {extra_divs}
            </div>
          </div>
          { this.state.activeActivity &&
            <StyledActivityForm onSubmit={this.submit} className="form">
              <div className="errors">
                {this.state.error}
              </div>
              <div/>
              <label>
                Name:
              </label> 
              <input 
                type={'text'} 
                value={activeActivity.name}
                onChange={this.changeActivityValue}
                name={'name'}
              />
              {this.state.activeActivity.type === "activity" &&
                <>
                  <label>
                    Color:
                  </label>
                  <SketchExample
                    color={activeActivity.color_code}
                    handleChange={this.changeActivityColor}
                    key={activeActivity.id}
                  />

                  <label>
                    Running Activity:
                  </label>
                  <Checkbox
                    checked={activeActivity.running}
                    onClick={this.clickRunningCheckbox}
                  />

                  {this.props.userData.is_coach && 
                    <>
                      <label>
                        Share With Athletes:
                      </label>
                      <Checkbox
                        checked={activeActivity.shared}
                        onClick={this.clickSharedCheckbox}
                      />
                    </>
                  }
                </>
              }
              <input type="submit" value="Save" />
            </StyledActivityForm>

          }
        </StyledActivityHolder>
      </>

    )
  }
}

function ExtraDiv({extra, ...props}){
  return(
    <div className="entry">
      <StyledExtraDiv>
        <span>{extra.name}</span>
      </StyledExtraDiv>
      <div/>
      <StyledEditButton>
        <EditButton
          onClick={(event =>{
            event.preventDefault();
            props.editExtra(extra.id)
          })}
        />
      </StyledEditButton>
      <div/>
    </div>
  )
}
function ActivityDiv(props){
  const activity=props.activity;
  const canEdit = activity.user_id === props.userData.id;
  return(
    <div key={activity.id} className="entry">
      <StyledActivityDiv  
        backgroundColor={activity.color_code}
      >
        <span>{activity.name}</span>
      </StyledActivityDiv>
      <div/>
      <StyledEditButton>
        <EditButton 
          onClick={(event) => {
              event.preventDefault();
              if(canEdit){
                props.editActivity(activity.id);
              }
            }
          }
          disabled={!canEdit}
        />
      </StyledEditButton>
      <div/>
    </div>
    
  )
}

class LoginModal extends React.PureComponent{

  handleEmailChange(event){
    this.setState({email: event.target.value})
  }

  handlePWChange(event){
    this.setState({password: event.target.value})
  }

  submit(event){
    let asyncResp = this.props.handleLoginSubmit(this.state.email, this.state.password);
    event.preventDefault();
    asyncResp.then((results_str) => {
      this.props.closeModal()
    })
    .catch((err) => {
      this.setState({error: "Incorrect Password."});
    })
  }
  constructor(props){
    super(props);
    this.state = ({error: null})
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePWChange = this.handlePWChange.bind(this);
    this.submit = this.submit.bind(this);
  }
  render(){
    return(
      <>
        <p>Sign in</p>
        <form onSubmit={this.submit}>
          <p>{this.state.error}</p>
          <label>
            Email:
            <input type="text" onChange={this.handleEmailChange}/>
          </label>
          <label>
            Password:
            <input type="password" onChange={this.handlePWChange}/>
          </label>
          <input type="submit" value="Submit" />
        </form>
      </>
    )
  }
}

class TopNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginModalOpen: false
    }
    this.sidenavRef = React.createRef();
    this.sidenavTabRef = React.createRef();
    this.closeNav = this.closeNav.bind(this);
    this.openNav = this.openNav.bind(this);
    this.openLoginModal = this.openLoginModal.bind(this);
    this.openActivitiesModal = this.openActivitiesModal.bind(this);
    this.openShoesModal = this.openShoesModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.logOut = this.logOut.bind(this);
  }
  closeNav = () => {
    console.log("close")
    this.sidenavRef.current.style.right = "-200px";
    this.sidenavTabRef.current.style.opacity = "100";
  }
  openNav = () => {
    console.log("open")
    this.sidenavRef.current.style.right = "0";
    this.sidenavTabRef.current.style.opacity = "0";
  }

  openActivitiesModal(){
    this.setState({loginModalOpen: false, activitiesModalOpen: true, modalIsOpen: true, shoesModalOpen: false})
  }
  openLoginModal = () => {
    this.setState({loginModalOpen: true, activitiesModalOpen: false, modalIsOpen: true, shoesModalOpen: false})
  }

  openShoesModal(){
    this.setState({loginModalOpen: false, activitiesModalOpen: false, modalIsOpen: true, shoesModalOpen: true})
  }

  closeModal = () => {
    this.setState({modalIsOpen: false})
  }

  loginSubmit = (email, password) => {
    return request
      .post(this.props.appData.rootUrl+'/user_token')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .send({auth: {email: email, password: password}})
      .then((results_str) => {
        let jwt = JSON.parse(results_str.text).jwt;
        console.log(jwt);
        this.props.setAppLoggedIn(true, jwt);
      })
      
    //localStorage.setItem('authToken', jwt)
  }

  logOut = () => {
    this.props.setAppLoggedIn(false);
  }

  render() {
    return(
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          className={"biggerlogModal"}
          overlayClassName={"biggerlogModalOverlay"}
        >
          {this.state.loginModalOpen &&
            <LoginModal 
              handleLoginSubmit={this.loginSubmit}
              closeModal={this.closeModal}
              appData={this.props.appData}
            />
          }
          {this.state.activitiesModalOpen && this.props.isLoggedIn &&
            <ActivitiesModal 
              activities_hash={this.props.userData.activities_hash}
              activities={this.props.userData.activities}
              extras={this.props.userData.extras}
              updateUserState={this.props.updateUserState}
              appData={this.props.appData}
              userData={this.props.userData}
            />
          }
          {this.state.shoesModalOpen && this.props.isLoggedIn &&
            <ShoesModal
              shoes_hash={this.props.userData.shoes_hash}
              shoes={this.props.userData.shoes}
              mileage_unit={this.props.userData.mileage_unit}
              updateUserState={this.props.updateUserState}
              appData={this.props.appData}
            />
          }
        </Modal>
          
        <div id="mySidenav" 
          ref={this.sidenavRef} 
          className="sidenav" 
          onMouseEnter={this.openNav} 
          onMouseLeave={this.closeNav}
        >
          {!this.props.isLoggedIn && 
            <LoginButton clickFun={this.openLoginModal}/>
          }

          {this.props.isLoggedIn &&
            <>
              <ActivitiesButton clickFun={this.openActivitiesModal}/>
              <ShoesButton clickFun={this.openShoesModal}/>
              <LogoutButton clickFun={this.logOut}/>
            </>
          }
          {this.props.isLoggedIn && this.props.userData && this.props.userData.is_coach && 
            <CoachPlanButton/>
          }
          <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/"             title="Flaticon">www.flaticon.com</a></div>
          <div id="navTab" ref={this.sidenavTabRef}>☰</div>
        </div>
      </>

    )
  }
}

export default TopNav;