import styled from 'styled-components';
import * as variables from './variables.js';
import {StyledCalendarTable} from './calendar_grid_styles.js';
const BROWSER_WIDTH = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
const INITIAL_CELL_WIDTH = 120;
const WEEK_CELL_WIDTH = Math.round((BROWSER_WIDTH-INITIAL_CELL_WIDTH)/7);
console.log(WEEK_CELL_WIDTH)
export const StyledCalendarRow = styled.div`
  cursor: pointer;
  display: grid;
  width: 100%;
  grid-template-columns: ${INITIAL_CELL_WIDTH}px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  
`

export const StyledCalendarTopRow = styled(StyledCalendarRow)`
  position: fixed;
  cursor: auto;
  div{
    border: 1.5px solid black;
  }
`

export const StyledCalendarDiv = styled.div`
  border: 1.5px solid ${variables.CALENDAR_BORDER};
  //border-right: 3px solid ${variables.CALENDAR_BORDER};
  //border-top: 3px solid ${variables.CALENDAR_BORDER};
`

export const StyledCalendarWeekStartDiv = styled(StyledCalendarDiv)`
  font-size: 12px;
  background-color: ${variables.INACTIVE_MONTH_COLOR};
  display: grid;
  grid-template-rows: 25px 1fr;
`

export const StyledLogDayDiv = styled(StyledCalendarDiv)`
  vertical-align: top;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : "#E6E6E6"};
  border: ${props => props.isOver ? '2.5px solid red' : '1.5px solid '+variables.INACTIVE_MONTH_COLOR};
  div{
    
  }
`

export const StyledTopRow = styled(StyledLogDayDiv)`

`
export const StyledCalendarWeekSummaryTd = styled.td`
  
`