import React from 'react';
import styled from 'styled-components';
import * as variables from '../styles/variables.js';
import {EditSpanStyled} from './edit_button.jsx';
export const DeleteSpanStyled = styled(EditSpanStyled)`
  
`
const DeleteButton = ({disabled, ...props }) => (
  <DeleteSpanStyled disabled={disabled} {...props}>
    <svg
     height="16" width="16" viewBox="0 0 512 512"
     y="0px"
     x="0px"
     id="Capa_1"
     version="1.1">
    <g
       id="g99">
      <g
       id="g97">
        <path
       id="path93"
       d="M87.748,388.784c0.461,11.01,9.521,19.699,20.539,19.699h191.911c11.018,0,20.078-8.689,20.539-19.699l13.705-289.316    H74.043L87.748,388.784z M247.655,171.329c0-4.61,3.738-8.349,8.35-8.349h13.355c4.609,0,8.35,3.738,8.35,8.349v165.293    c0,4.611-3.738,8.349-8.35,8.349h-13.355c-4.61,0-8.35-3.736-8.35-8.349V171.329z M189.216,171.329    c0-4.61,3.738-8.349,8.349-8.349h13.355c4.609,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.737,8.349-8.349,8.349h-13.355    c-4.61,0-8.349-3.736-8.349-8.349V171.329L189.216,171.329z M130.775,171.329c0-4.61,3.738-8.349,8.349-8.349h13.356    c4.61,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.738,8.349-8.349,8.349h-13.356c-4.61,0-8.349-3.736-8.349-8.349V171.329z" />
        <path
       id="path95"
       d="M343.567,21.043h-88.535V4.305c0-2.377-1.927-4.305-4.305-4.305h-92.971c-2.377,0-4.304,1.928-4.304,4.305v16.737H64.916    c-7.125,0-12.9,5.776-12.9,12.901V74.47h304.451V33.944C356.467,26.819,350.692,21.043,343.567,21.043z" />
      </g>
    </g>
    <g
       id="g101">
    </g>
    <g
       id="g103">
    </g>
    <g
       id="g105">
    </g>
    <g
       id="g107">
    </g>
    <g
       id="g109">
    </g>
    <g
       id="g111">
    </g>
    <g
       id="g113">
    </g>
    <g
       id="g115">
    </g>
    <g
       id="g117">
    </g>
    <g
       id="g119">
    </g>
    <g
       id="g121">
    </g>
    <g
       id="g123">
    </g>
    <g
       id="g125">
    </g>
    <g
       id="g127">
    </g>
    <g
       id="g129">
    </g>
  </svg>

  </DeleteSpanStyled>
)

export default React.memo(DeleteButton);