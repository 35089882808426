import styled from 'styled-components';
import * as variables from './variables.js';
import Button from '../helpers/button.jsx';
export const StyledActivityDiv = styled.div`
  background-color: ${props => props.backgroundColor}
`

export const StyledExtraDiv = styled.div`

`

export const StyledActivityHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  overflow-y: scroll;
  .new{
    justify-self: start;
    margin-bottom: 10px;
  }
  div.right{
    .list-holder{
      .entry:nth-child(2n){
        div:first-child{
          background-color: ${variables.LIST_ALTERNATE_BG};
        }
      }
      .entry:nth-child(2n+1){
        div:first-child{
          background-color: ${variables.LIST_BG};
        }
      }
    }
  }
  div.list-holder{
    display: grid;
    div.entry{
      display: grid;
      grid-template-columns: 150px 50px 50px 1fr;
    }
    overflow-y: scroll;
    grid-row-gap: 5px;


  }
`
export const StyledEditButton = styled.span`
  //float: right;
`

export const StyledRetireButton = styled.div`

  //float: right;
`

export const StyledActivateButton = styled.div`
  //float: right;
`
export const StyledActivityForm = styled.form`
  display: grid;
  grid-template-columns: 150px 175px;
  align-content: start;
  div.errors{
    color: red;
  }
`

export const ShowShoes = styled(Button)`
  float: ${props => props.show ? 'left' : 'right'};
`

export const StyledShoeHolder = styled(StyledActivityHolder)`
  width: 700px;
  margin: 0 auto;
  border: 3px solid black;
  border-radius: 10px 10px 0 0;
  grid-template-columns: 1fr;
  .shoe-holder{
    display: grid;
    grid-template-columns: 1fr;
    .entry{
      &:nth-child(2n){
        background-color: ${variables.LIST_ALTERNATE_BG};
      }
      display: grid;
      grid-template-columns: 2fr 1fr 0.5fr 50px;
      &:not(:last-child){
        border-bottom: 2px solid black;
      }
      &:not(:first-child){
        div:not(:last-child){
          border-right: 1px solid black;
        }
      }
      div{
        &:nth-child(-n+2){
          padding: 5px 0 5px 0;
          margin-left: 10px;
        }
      }
      a{

        display: inline-block;
        width: 95%;
        height: 90%;
        text-align: center;
      }
    }
  }
  
`

export const StyledShoeForm = styled(StyledActivityForm)`
  
`