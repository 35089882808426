import React from 'react';
import CalendarWeek from './calendar_week.jsx'

class CalendarPage extends React.PureComponent {
 
  constructor(props){
    super(props);
    this.botRowRef = React.createRef();
    this.state = {display: true};
  }

  renderWeek(week_data, i){
    return(
      <CalendarWeek 
        key={week_data.date}
        week_data={week_data}
        expanded={true}
        deleteDay={this.props.deleteDay}
        openModal={this.props.openModal}
        closeModal={this.props.closeModal}
        userData={this.props.userData}
        pageUserData={this.props.pageUserData}
        appData={this.props.appData}
        isActive={this.props.page.isActive}
        canDisplay={this.state.display}
        setHeaderYear={this.props.setHeaderYear}
        observer={this.props.observer}
        createAssignment={this.props.createAssignment}
      />
    )
  }

  // componentWillMount(){
  //   this.lastScrollHeight = document.documentElement.scrollHeight;
  //   console.log("will mount")
  // }
  // componentDidMount(){
  //   console.log(this.botRowRef);
  //   var scrollDiff = document.documentElement.scrollHeight - this.lastScrollHeight;
  //   this.setState({display: true}, () => {
  //     document.documentElement.scrollTop += scrollDiff;
  //   })
  // }

  render(){ 
    var page = this.props.page;
    if(page.isActive){
      let page_length = page.dates.length;
      return(
        <>
        {
          page.dates.map((week_data, i) => {
            if(i === page_length-1){
              week_data.row_ref = this.botRowRef;
            }
            return this.renderWeek(week_data, i);
          })
        }
        </>
      )
    }else{
      return(<tr></tr>)
    }
  }
}

export default CalendarPage;