import React from 'react';
class CoachPage extends React.PureComponent{

  render(){
    return (
      <div>
       "hello world"
      </div>
    )
  }
}

export default CoachPage;