const MONTHNAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const startOfWeek = (date) => {
  var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  var ret = new Date(date);
  ret.setDate(diff);
  return ret; 
}

export const parseDate = (str) => {
  return new Date(str + " 00:00");
}

//https://stackoverflow.com/questions/5191088/how-to-round-up-a-number-in-javascript
export const roundIt = (num, precision) => {
  var rounder = Math.pow(10, precision);
  return (Math.round(num * rounder) / rounder)
};

export const toDateString = (date) => {
  let y = date.getFullYear();
  let m = (date.getMonth()+1).toString();
  if (m.length === 1){
    m = "0"+m;
  }
  let d = date.getDate().toString();
  if (d.length === 1){
    d = "0"+d;
  }
  return (""+y+"-"+m+"-"+d)
}

export const convertUnit = (amount, original_unit, target_unit) => {
  let a = amount;
  if (target_unit === "mi"){
    if(original_unit === "km"){
      a = amount/1.60934;
    }else if(original_unit === "m"){
      a = amount/1609.34;
    }
  }else if(target_unit === "km"){
    if(original_unit === "mi"){
      a = amount*1.60934;
    }else if(original_unit === "m"){
      a = amount/1000;
    }
  }
  return a;
}
export const toDateRange = (date) => {
  let _date = new Date(date)
  let last_date = addDays(_date, 6);
  //let y = date.getFullYear();
  let m = MONTHNAMES[_date.getMonth()];
  let d = _date.getDate().toString();
  //let last_y = last_date.getFullYear();
  let last_m = MONTHNAMES[last_date.getMonth()];
  let last_d = last_date.getDate().toString();
  let ret = m + " " + d;
  // if (y !== last_y){
  //   ret += " " + y;
  // }
  ret+= "-";
  if (m !== last_m){// || y !== last_y){
    ret+= last_m + " ";
  }
  ret += last_d// + " " + last_y;
  return ret;
}