import React from 'react';

const ActivitySelect = ({activities, activity_id, ...props}) => {
  var activityOptions = activities.map((activity) => {
    return(
      <option style={{backgroundColor: activity.color_code}} key={activity.id} value={activity.id}>
        {activity.name}
      </option>
    )
  });
  if(props.hasNull){
    const newOp = (
      <option key={0} value={""}>
      </option>
    )
    activityOptions = [newOp, ...activityOptions];
  }
  return(
    <select
      value={activity_id}
      name={props.name}
      onChange={props.onChange}
      className={props.className}
    >
      {activityOptions}
    </select>

  )
}


export default ActivitySelect;