export const BACKGROUND = "#FFFFFF";
export const DARK_RED = "#910013";
export const LIGHT_DARK_RED = "#ce0826";
export const LIGHTER_DARK_RED = "#e01836";
export const VERY_LIGHT_RED = "#FFEAEA";
export const LIGHTEST_RED = "#f2e1e1"

export const ACTIVE_MONTH_COLOR = "rgba(240, 240, 240, 1)";
export const ACTIVE_MONTH_COLOR_HOVER = "rgba(221, 219, 219, 1)";

export const INACTIVE_MONTH_COLOR = "rgba(192, 192, 192, 1)"; // 173 171 171
export const INACTIVE_MONTH_COLOR_HOVER = "#adabab";

export const CALENDAR_DATE_COLOR = "#1B3E9B";
export const BORDER_COLOR = "#7c6666";
export const TOP_RIGHT_BLUE = "#A0B7C9";

export const CALENDAR_BORDER = "black";

export const LIST_BG = "white";
export const LIST_ALTERNATE_BG = "#d8d8d8";