import React from 'react';
import styled from 'styled-components';
import * as variables from '../styles/variables.js';

export const EditSpanStyled = styled.span`
  svg:hover{
    fill: ${props => props.disabled ? 'black' : variables.DARK_RED};
  }
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`
const EditButton = ({disabled, ...props }) => (
  <EditSpanStyled disabled={disabled} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M352.5 220c0-11-9-20-20-20h-206a20 20 0 1 0 0 40h206c11 0 20-9 20-20zM126.5 280a20 20 0 1 0 0 40h125a20 20 0 1 0 0-40h-125z"/><path d="M173.5 472h-67c-22 0-40-18-40-40V80c0-22 18-40 40-40h246c22 0 40 18 40 40v123a20 20 0 1 0 40 0V80a80 80 0 0 0-80-80h-246a80 80 0 0 0-80 80v352a80 80 0 0 0 80 80h67a20 20 0 1 0 0-40z"/><path d="M467.9 289.6a60 60 0 0 0-84.9 0L273.2 399a20 20 0 0 0-5 8.4l-23.9 78.7a20 20 0 0 0 24.5 25l80.7-22.3a20 20 0 0 0 8.8-5.1L468 374.4a60 60 0 0 0 0-84.8zM333.8 451.8L293.2 463 305 424l74.1-74 28.3 28.4-73.6 73.5zM439.6 346l-3.9 3.9-28.2-28.3 3.8-3.8a20 20 0 0 1 28.3 28.2zM332.5 120h-206a20 20 0 1 0 0 40h206a20 20 0 1 0 0-40z"/></svg>
  </EditSpanStyled>
)

export default React.memo(EditButton);