import React from 'react';
import RunningLog from './running_log.jsx';
import {StyledLogDayDiv} from './styles/calendar_week_styles.js';
import {LogDayDateStyled, StyledRunningLog, CalendarEditSpan, CalendarDeleteSpan} from './styles/log_day_styles.js';
import {parseDate} from './helpers.jsx';
import * as variables from './styles/variables.js';
import EditButton from './helpers/edit_button.jsx';
import DeleteButton from './helpers/delete_button.jsx';
import { useDrop } from 'react-dnd'
import DragTypes from './helpers/drag_types.js';
export const getLogActivity = (running_log, userActivities) => {
  return(userActivities[running_log.activity_id] || running_log.activity);
  // return(activity === null ? "#E6E6E6" : activity.color_code)
}

//https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
export function shadeHexColor(color, percent) {
    var f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF;
    return "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
}

export const getActivityColor = (log_day, userActivities) => {
  let highest_log = null;
  let highest_log_activity = null;
  log_day.running_logs.forEach(running_log => {
    let running_log_activity = getLogActivity(running_log, userActivities);
    if (highest_log === null || highest_log_activity.priority < running_log_activity.priority){
      highest_log = running_log;
      highest_log_activity = running_log_activity;
    }
  });
  if (highest_log === null){
    return "#E6E6E6";
  }
  return highest_log_activity.color_code;
}

export const createDateCell = (date) =>{
  const cell_date = parseDate(date);
  if(cell_date.getDate() === 1){
    return <LogDayDateStyled>{cell_date.toLocaleDateString("en-US", {month: 'short', day: "numeric"})}</LogDayDateStyled>;
  }
}

function createDeleteButton(hovering, deleteDayFn) {
  return(
    <CalendarDeleteSpan hovering={hovering}>
      <DeleteButton
        onClick={deleteDayFn}
      />
    </CalendarDeleteSpan>
  )
}
function createEditButton(hovering, editDayFn) {
  return(
    <CalendarEditSpan hovering={hovering}>
      <EditButton
        onClick={editDayFn}
      />
    </CalendarEditSpan>
  )
}
function LogDay(props) {
  const deleteDay = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.deleteDay(props.log_day)
  }
  const clickDay = (event) => {
    console.log('click');
    event.preventDefault();
    event.stopPropagation();
    props.editDay(props.log_day)
  }

  const [{ isOver }, dropRef] = useDrop({
    accept: DragTypes.RUNNING_LOG,
    drop: (item, monitor) => {
      props.createAssignment(monitor.getItem().id, props.log_day.log_date);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  })
  let activities;
  if(props.userData){
    activities = props.userData.activities_hash;
  }else{
    activities = {}
  }
  let backgroundColor = variables.ACTIVE_MONTH_COLOR;
  let hoveringEditButton, hoveringDeleteButton;
  if(props.pageUserData.permissions.can_write_assignments || props.pageUserData.can_write_running_logs){
    hoveringEditButton = createEditButton(props.hovering, clickDay);
    if(props.log_day.running_logs.length > 0){
      hoveringDeleteButton = createDeleteButton(props.hovering, deleteDay)
    }
  }
  if(props.hovering){
    backgroundColor = variables.ACTIVE_MONTH_COLOR_HOVER;
  }
  
  const cell_date = createDateCell(props.log_day.log_date);
  const running_logs = props.log_day.running_logs.map((running_log) => {
    return(
      <RunningLog key={running_log.id} running_log={running_log} hovering={props.hovering} userData={props.userData}/>
    )
  })
  return(
    <StyledLogDayDiv
      key={props.log_day.log_date}  
      backgroundColor={backgroundColor}
      ref={dropRef}
      isOver={isOver}
    >
      {running_logs}
      {cell_date}
      {hoveringEditButton}
      {hoveringDeleteButton}
    </StyledLogDayDiv>
  )
  
}
export default LogDay;