import React from 'react';
import {getActivityColor, getLogActivity} from './log_day.jsx';
import RunningLog, {description, secondsTimeSpanToHMSArr, getPace} from './running_log.jsx';
import {buildHeaders} from './helpers/auth.jsx';
import ActivitySelect from './helpers/activity_select.jsx';
import {toDateRange, parseDate, convertUnit} from './helpers.jsx';
import {weekSummary} from './calendar_week.jsx'
import request from "superagent";
import {
  DateHeaderStyled, 
  WeekModalStyled, 
  RunningLogFormStyled, 
  RunningLogFormLeft,
  RunningLogFormRight,
  SelectorUlStyled,
  FormButtons
  } from './styles/week_modal_styles.js';
import SelectBox from './select_box.jsx';
import Checkbox from './helpers/checkbox.jsx';
import update from 'immutability-helper';
function LogModal(props){
  let log_activity = getLogActivity(props.running_log, props.userData.activities_hash);
  const color_code = props.running_log.is_assignment ? 'red' : log_activity.color_code;
  return(
    <td 
      className="LogModal" 
      style={
        {backgroundColor: log_activity.color_code, border: "2px solid "+color_code}
      }
    >
      <p><b> 
        {log_activity.name} {description(props.running_log)}
      </b></p>
      <p>{props.running_log.content}</p>
    </td>
  )
}

function DayModal(props){
  function clickDay(event) {
    event.preventDefault();
    props.editDay(props.log_day);
  }
  let activities;
  if(props.userData){
    activities = props.userData.activities_hash;
  }else{
    activities = {};
  }
  return(
    <td 
      className={'DayModal'}
      onClick={clickDay}
    >

      <table>
          <tbody>
            {props.log_day.running_logs.map((running_log,i) => (
              <tr key={i}>
                <LogModal 
                  running_log={running_log} 
                  key={i}
                  userData={props.userData}
                />
              </tr>
            ))}
          </tbody>
        </table>
    </td>
  )
}

function calcRlDuration(running_log){
  let h_i = parseInt(running_log.hours)   || 0;
  let m_i = parseInt(running_log.minutes) || 0;
  let s_i = parseInt(running_log.seconds) || 0;
  return h_i*3600 + m_i*60 + s_i;
}
export const RunningLogFormPartial = (props) => {
  //if not same user
  //only show activities that are shared
  //joins table for activity order
  //different users can have activities in different order
  const calculatePace = () => {
    const _activeRunningLog = props.runningLog;
    return getPace(
      _activeRunningLog.distance_unit, 
      _activeRunningLog.distance, 
      _activeRunningLog.distance_unit, 
      calcRlDuration(_activeRunningLog)
    ) || "NaN"
  }
  const paceUnit = () => {
    return "min/"+props.runningLog.distance_unit;
  }

  let runningLog = props.runningLog;
  let activities = props.activities;
  let extras = props.userData.extras;
  let shoes = props.userData.shoes;
  let shoe_list;
  if(!props.isCoach){
    shoe_list = shoes.map((shoe) => {
      if(!shoe.retired){
        let mileage = convertUnit(shoe.mileage.split(" ")[0], shoe.mileage.split(" ")[1], props.userData.mileage_unit)
        return(
          <option 
            key={shoe.id} 
            value={shoe.id}
          >
            {shoe.name + "(" + mileage + " " + props.userData.mileage_unit + ")"}
          </option>    
        )
      }else{
        return null;
      }
    });
  }

  return (
    <RunningLogFormStyled>
      <RunningLogFormLeft>
        {props.pageUserData.permissions.can_write_running_logs && !props.isCoach &&

          <>
            <label>
              Is Assignment:
            </label>

            <Checkbox
              checked={runningLog.is_assignment}
              onClick={props.clickAssignmentCheckbox}
              name={props.index+" is_assignment"}
            />
          </>
        }
        <label className="activity-label">
          Activity:
        </label>
        <ActivitySelect 
          className="activity"
          activity_id={runningLog.activity_id} 
          onChange={(event) => {
            event.preventDefault();
            props.updateRunningLog(props.index, 'activity_id', event.target.value)
          }}
          activities={activities}
        />
        <label className="description-label">
          Description:
          </label>
        <input 
          className="description"
          value={runningLog.substitute_description || ""} 
          type={'text'} 
          onChange={(event) => {
            event.preventDefault();
            props.updateRunningLog(props.index, 'substitute_description', event.target.value)
          }}
        />
        <label className="distance-label">
          Distance:
        </label>
        <div className="distance">
          <input 
            className="distance"
            value={!runningLog.distance ? '' : runningLog.distance}
            placeholder={0}
            type={'text'} 
            onChange={(event) => {
              event.preventDefault();
              props.updateRunningLog(props.index, 'distance', event.target.value)
            }}
          />
          <select 
            className="unit"
            value={runningLog.distance_unit || props.userData.mileage_unit} 
            onChange={(event) => {
              event.preventDefault();
              props.updateRunningLog(props.index, 'distance_unit', event.target.value)
            }}
          >
            <option value={'mi'}>mi</option>
            <option value={'km'}>km</option>
            <option value={'m'} >m </option>
          </select>
        </div>

        <label className="duration-label">Duration:</label>
        <div className="duration">
          <input 
            value={(runningLog.hours === 0 ? "" : runningLog.hours) || ""}
            type={'text'}
            onChange={(event) => {
              event.preventDefault();
              props.updateRunningLog(props.index, 'hours', event.target.value)
            }}
            maxLength={2}
            className="duration-hours"
          />
          <span>H</span>
          <input 
            value={(runningLog.minutes === 0 ? "" : runningLog.minutes) || ""}
            type={'text'}
            onChange={(event) => {
              event.preventDefault();
              props.updateRunningLog(props.index, 'minutes', event.target.value)
            }}
            maxLength={2}
          />
          <span>M</span>
          <input 
            value={(runningLog.seconds === 0 ? "" : runningLog.seconds) || ""}
            type={'text'}
            onChange={(event) => {
              event.preventDefault();
              props.updateRunningLog(props.index, 'seconds', event.target.value)
            }}
            maxLength={2}
          />
          <span>S</span>
        </div>
        
        <label className="pace-label">Pace:</label>
        <div className="pace">
          <input
            value={calculatePace()}
            type={'text'}
            disabled={true}
            className="pace"
          />
          <span>{paceUnit()}</span>
        </div>
      
        {props.pageUserData.permissions.can_write_running_logs && !props.isCoach &&
          <>
            <label className="shoe-label">
              Shoe:
            </label>
            <select 
              className="shoe"
              value={runningLog.shoe_id || 0} 
              onChange={(event) => {
                event.preventDefault();
                props.updateRunningLog(props.index, 'shoe_id', event.target.value)
              }}
            >
              <option value={0}></option>
              {shoe_list}
            </select>
          </>
        }
        
      </RunningLogFormLeft>
      <RunningLogFormRight>
        <label className="notes-label">
          Notes:
          </label>
        <textarea 
          className="notes"
          value={runningLog.content || ""} 
          onChange={(event) => {
            event.preventDefault();
            props.updateRunningLog(props.index, 'content', event.target.value)
          }}
        />

        {props.pageUserData.permissions.can_write_running_logs && !props.isCoach &&
          <> 
            <label className="extras-label">
              Extras:
            </label>
            <SelectBox
              items={extras}
              selected_ids={runningLog.extra_ids}
              addExtra={props.addExtra}
              removeExtra={props.removeExtra}
            />
          </>
        }
      </RunningLogFormRight>

    </RunningLogFormStyled>
  )
}

function RunningLogSelectorPartial(props){
  let selectorClassName = "list-element";
  let i = props.activityIndex
  if(i === props.stateActivityIndex){
    selectorClassName+=" selected"
  }
  return(
    <div className={selectorClassName}>
      <span 
        onClick={(e) => props.changeActiveActivity(e, i)}
        activity_ix={i}
      >
        <RunningLog running_log={props.runningLog} userData={props.user}/>
      </span>
      
    </div>
 
  )
}

class LogWeekForm extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      logWeek: props.logWeek,
    }
    this.submit = this.submit.bind(this);
    this.updateWeek = this.updateWeek.bind(this);
  }

  updateWeek(field, value){
    this.setState((oldState) => {
      let week = oldState.logWeek;
      let newWeek = update(week, {
        [field]: {$set: value}
      })
      return{
        logWeek: newWeek,
      }
    })
  }

  submit(event){
    event.preventDefault();
    let week = this.state.logWeek;
    const user_id = this.props.pageUserData.id;
    let req;
    if(week.id === null){
      req = request('POST', this.props.appData.rootUrl+'/users/'+user_id+'/log_weeks/')
    }else{
      req = request('PUT', this.props.appData.rootUrl+'/users/'+user_id+'/log_weeks/'+week.id)
    }
    const week_slimmed = {id: week.id, date: week.date, user_notes: week.user_notes}
    req
      .set('Content-Type', 'application/json')
      .set('Authorization', buildHeaders())
      .set('Accept', 'application/json')
      .send({log_week: week_slimmed})
      .then((results_str) => {
        console.log(results_str)
        let new_week = JSON.parse(results_str.text).log_week;
        this.props.percolateLogWeekUpdate(new_week)
        this.props.exitEditDay()
      })
      // .catch((err) => {
      //   console.log(err.response)
      //   let error_json = JSON.parse(err.response.text).errors.running_logs;
      //   let error_array = Object.keys(error_json).map((key) => {
      //     return error_json[key].map((message) =>{
      //       return(key+": "+message);
      //     })
      //   })
      //   this.setState({error: error_array});
      // })
  }
  render(){
    const week = this.state.logWeek;
    return(
      <>
        <h3>{toDateRange(this.state.logWeek.date)}</h3>
        <label>Notes:</label>
        <input 
          className="userNotes"
          value={week.user_notes || ""} 
          type={'text'} 
          onChange={(event) => {
            event.preventDefault();
            this.updateWeek('user_notes', event.target.value)
          }}
        />
        <input type="submit" onClick={this.submit} value="Save" />
      </>
    )
  }

}
class LogDayForm extends React.Component{
  constructor(props){
    super(props);
    let logDay = props.logDay;
    // if (logDay.id === null && (logDay.running_logs === null || logDay.running_logs.length === 0)){
    //   logDay.running_logs = [this.createNewRunningLog()]
    // }
    if (logDay.running_logs === null){
      logDay = update(logDay, {
        running_logs: {$set: []}
      });
    }
    let newLogDay;
    logDay.running_logs.forEach((running_log, i) => {
      let [h,m,s] = secondsTimeSpanToHMSArr(running_log.duration_s);
      newLogDay = update(logDay, {
        running_logs: {hours: {$set: h}},
        running_logs: {minutes: {$set: m}},
        running_logs: {seconds: {$set: s}},
      });
      running_log.hours = h;
      running_log.minutes = m;
      running_log.seconds = s;
    })
    this.state = {
      logDay: logDay,
      error: null,
      activityIndex: 0,
    }
    this.changeActiveActivityEventHandler = this.changeActiveActivityEventHandler.bind(this);
    this.updateRunningLog = this.updateRunningLog.bind(this);
    this.submit = this.submit.bind(this);
    this.addRunningLog = this.addRunningLog.bind(this);
    this.deleteRunningLog = this.deleteRunningLog.bind(this);
    this.addExtra = this.addExtra.bind(this);
    this.removeExtra = this.removeExtra.bind(this);
    this.clickAssignmentCheckbox = this.clickAssignmentCheckbox.bind(this);
  }

  clickAssignmentCheckbox(){
    let value = !this.activeRunningLog().is_assignment;
    this.updateRunningLog(this.state.activityIndex, 'is_assignment', value);
  }
  addExtra(extra_id){
    const _activeRunningLog = this.activeRunningLog();
    if(_activeRunningLog.extra_ids.findIndex(e => parseInt(e) === parseInt(extra_id)) > -1){
      return null;
    }
    const new_extra_ids = [..._activeRunningLog.extra_ids, extra_id];
    this.updateRunningLog(this.state.activityIndex, 'extra_ids', new_extra_ids);
  }

  removeExtra(extra_id){
    const _activeRunningLog = this.activeRunningLog();
    const new_extra_ids = _activeRunningLog.extra_ids.filter(e => parseInt(e) !== parseInt(extra_id));
    this.updateRunningLog(this.state.activityIndex, 'extra_ids', new_extra_ids);
  }
  activeRunningLog(){
    console.log(this.state.logDay)
    return this.state.logDay.running_logs[this.state.activityIndex];
  }

  createNewRunningLog(){
    return {activity_id: this.props.pageUserData.activities[0].id, 
      distance: 0, 
      distance_unit: this.props.pageUserData.mileage_unit,
      shoe_id: 0,
      extra_ids: [],
      is_assignment: false,
      substitute_description: null,}
  }
  
  submit(event){
    event.preventDefault();
    let rl = this.state.logDay;
    rl.running_logs.forEach((running_log) => {
      if(parseInt(running_log.shoe_id) === 0){
        running_log.shoe_id = null;
      }
    })
    const user_id = this.props.pageUserData.id;
    let req;
    if(rl.id === null){
      req = request('POST', this.props.appData.rootUrl+'/users/'+user_id+'/log_days/')
    }else{
      req = request('PUT', this.props.appData.rootUrl+'/users/'+user_id+'/log_days/'+rl.id)
    }
    req
      .set('Content-Type', 'application/json')
      .set('Authorization', buildHeaders())
      .set('Accept', 'application/json')
      .send({log_day: rl})
      .then((results_str) => {
        let new_ld = JSON.parse(results_str.text).log_day;
        // this.setState((oldState, oldProps) => {
        //   console.log(oldState)
        //   const ld_ix = oldState.logWeek.indexOf(ld => ld.log_date === new_ld.log_date)
        //   const new_log_week = update(oldState.logWeek, {
        //     log_days: {[ld_ix]: {$set: new_ld}}
        //   });
        //   return {logWeek: new_log_week};
        // })
        this.props.percolateLogDayUpdate(new_ld, this.props.logWeek)
        this.props.exitEditDay()
      })
      .catch((err) => {
        console.log(err.response)
        let error_json = JSON.parse(err.response.text).errors.running_logs;
        let error_array = Object.keys(error_json).map((key) => {
          return error_json[key].map((message) =>{
            return(key+": "+message);
          })
        })
        this.setState({error: error_array});
      })
  }

  updateRunningLog = (rl_ix, field, value) => {
    this.setState((oldState)=> {
      const ld = oldState.logDay;
      if(field === 'shoe_id' && parseInt(value) === 0){
        value = null;
      }
      var new_ld;
      if(field === "hours" || field === "minutes" || field === "seconds"){
        ld.running_logs[rl_ix][field] = value;
        const duration = calcRlDuration(ld.running_logs[rl_ix]);
        new_ld = update(ld, {
          running_logs: {[rl_ix]: {duration_s: {$set: duration}}}
        })
      }else{
        new_ld = update(ld, {
          running_logs: {[rl_ix]: {[field]: {$set: value}}}
        })
      }
      return {logDay: new_ld}
    })
  }

  deleteRunningLog(_ix){
    if (!window.confirm("Are you sure you want to delete this activity?")){
      return null;
    }
    let ld = this.state.logDay;
    let targetIndex = _ix
    let targetId = ld.running_logs[targetIndex].id;
    if(targetId){
      if(!ld.delete_running_logs){
        ld = update(ld, {
          delete_running_logs: {$set: [targetId]}
        });
        //ld.delete_running_logs = [targetId]
      }else{
        ld = update(ld, {
          delete_running_logs: {$set: [...ld.delete_running_logs, targetId]}
        });
        //ld.delete_running_logs = [...ld.delete_running_logs, targetId];
      }
    }
    console.log("length is :"+ld.running_logs.length+", index to delete is :"+targetIndex)
    ld = update(ld, {
      running_logs: {$splice: [[targetIndex, 1]]}
    })

    //ld.running_logs.splice(targetIndex, 1);
    // if(ld.running_logs.length === 0){
    //   ld.running_logs = [this.createNewRunningLog()]
    // }
    let newIndex = this.state.activityIndex;
    if(ld.running_logs.length === newIndex){
      newIndex--;
      if(newIndex < 0){newIndex = 0;}
    }
    this.setState({logDay: ld, activityIndex: newIndex});
  }

  addRunningLog(event){
    event.preventDefault();
    let ld = update(this.state.logDay, {
      running_logs: {$push: [this.createNewRunningLog()]}
    });
    this.setState({logDay: ld});
    this.changeActiveActivity(ld.running_logs.length-1)
  }

  changeActiveActivityEventHandler = (event, activity_id) => {
    event.preventDefault();
    this.changeActiveActivity(parseInt(activity_id));
  }

  changeActiveActivity(activity_id){
    this.setState({activityIndex: activity_id}); 
  }

  componentDidUpdate(prevprops){
    const logDay = this.state.logDay;
    if (logDay.running_logs === null || logDay.running_logs.length === 0){
      const newLogDay = update(logDay, {
        running_logs: {$set: [this.createNewRunningLog()]}
      });
      //logDay.running_logs = [this.createNewRunningLog()]
      this.setState({logDay: newLogDay});
    }
  }
  render(){
    let _activeRunningLog = this.activeRunningLog();
    let activities;
    if(this.props.userData.id === this.props.pageUserData.id){
      activities = this.props.pageUserData.activities;
    }else{
      activities = this.props.userData.activities.filter((act) => {
        return act.shared;
      })
    }
    return(
      <form onSubmit={this.submit}>
        <p>{this.state.error}</p>
        <div className="my-form-tab log-day-tab">
          <SelectorUlStyled>
            {
              this.state.logDay.running_logs.map((running_log, i) => {

                return(
                  <RunningLogSelectorPartial 
                    stateActivityIndex={this.state.activityIndex}
                    activityIndex={i}
                    key={i}
                    changeActiveActivity={this.changeActiveActivityEventHandler}
                    deleteRunningLog={this.deleteRunningLog}
                    runningLog={running_log}
                    user={this.props.pageUserData}
                  />
                )
              })
            }
            <div className="add_log" onClick={this.addRunningLog}>
              <span>+</span>
            </div>
          </SelectorUlStyled>
        </div>
        {_activeRunningLog && 
          <RunningLogFormPartial
            runningLog={_activeRunningLog}
            userData={this.props.userData}
            pageUserData={this.props.pageUserData}
            key={_activeRunningLog.id}
            updateRunningLog={this.updateRunningLog}
            index={this.state.activityIndex}
            addExtra={this.addExtra}
            removeExtra={this.removeExtra}
            clickAssignmentCheckbox={this.clickAssignmentCheckbox}
            activities={activities}
          />
        }
        <FormButtons>
          <input type="submit" value="Submit" />
          <input 
            className="remove-log biggerlogButton"
            onClick={(e) => {this.deleteRunningLog(this.state.activityIndex)}}
            value="Delete"
            readOnly={true}
          />
        </FormButtons>
      </form>
    )
  }
}

class WeekModal extends React.Component {

  render(){
    console.log("render week")
    let week = this.props.logWeek;
    if (week === null){
      return(
        <h3>NotLoaded</h3>
      )
    }else if (this.props.editingDay){
      return(
        <>
          <p 
            className="biggerlogButton"
            onClick={this.props.exitEditDay}
          >   ← Return to week
          </p>
          <LogDayForm 
            logDay={this.props.logDay} 
            userData={this.props.userData}
            pageUserData={this.props.pageUserData}
            logWeek={this.props.logWeek}
            percolateLogDayUpdate={this.props.percolateLogDayUpdate}
            percolateLogWeekUpdate={this.props.percolateLogWeekUpdate}
            exitEditDay={this.props.exitEditDay}
            appData={this.props.appData}
          />
        </>
      )
    }else if (this.props.editingWeek){
      return(
        <>
          <p 
            className="biggerlogButton"
            onClick={this.props.exitEditDay}
          >   ← Return to week
          </p>
          <LogWeekForm 
            userData={this.props.userData}
            pageUserData={this.props.pageUserData}
            logWeek={this.props.logWeek}
            percolateLogWeekUpdate={this.props.percolateLogWeekUpdate}
            exitEditDay={this.props.exitEditDay}
            appData={this.props.appData}
          />
        </>
      )
    }else{
      const days = week.log_days.map(log_day => {
        const date = parseDate(log_day.log_date);
        return (
          <DateHeaderStyled key={date.getTime()}>{date.toLocaleDateString("en-US", {
            weekday: "long", month: 'short', day: "numeric",
          })
          }
          </DateHeaderStyled>
        )
      })
      return(
        <>
          <p 
            className="weekChange weekUp"
            onClick={this.props.upWeek}
          >
            &#8593;
          </p>
          <h2 
            className="closeModal biggerlogButton"
            onClick={this.props.closeModal}
          >
              X
          </h2>
          <h4>{week.date.split("-")[0]} - This week {weekSummary(week, this.props.userData.mileage_unit)}</h4>
          <WeekModalStyled>
            <tbody>
              <tr className="dayNames">
                {days}
              </tr>
              <tr>
                {week.log_days.map(log_day => (
                  <DayModal 
                    log_day={log_day} 
                    key={log_day.log_date}
                    isLoggedIn={this.props.isLoggedIn}
                    editDay={this.props.editDay}
                    userData={this.props.userData}
                  />
                ))}
                <WeekSummary
                  week={week}
                  userData={this.props.userData}
                  pageUserData={this.props.pageUserData}
                  editWeek={this.props.editWeek}
                />
              </tr>
            </tbody>
          </WeekModalStyled>
          <p 
            className="weekChange weekDown"
            onClick={this.props.downWeek}
          >
            &#8595;
          </p>
        </>
      )
    }
  }
}

function WeekSummary(props){
  return(
    <td className="weekSummary"
        onClick={(e) => props.editWeek(props.week)}
    >
      <div>
        {weekSummary(props.week, props.userData.mileage_unit)}
      </div>
      <div>
        <p>{props.week.user_notes}</p>
      </div>
    </td>
  )
}
export default WeekModal;