import styled from 'styled-components';
import * as variables from './variables.js';

export const StyledCalendarTable = styled.div`
  width: 100%;
`

export const LoadingOverlay = styled.div`
z-index: 99;
background-color: grey;
opacity: 0.7;
width: 100%;
height: 100%;
position: fixed;
svg{
  position: fixed;
  left: 45%;
  top: 45%;
}
`