import React from 'react';
import request from "superagent";
import { buildHeaders} from './helpers/auth.jsx';
import {StyledCoachDashboard, TemplateHolder} from './styles/coach_dashboard_styles.js';
import RunningLog from './running_log.jsx';
import { useDrag } from 'react-dnd'
import DragTypes from './helpers/drag_types.js';
import ActivitySelect from './helpers/activity_select.jsx';
import Modal from 'react-modal';
import {RunningLogFormPartial} from './week_modal.jsx';
import update from 'immutability-helper';

class CoachDashboard extends React.PureComponent {

  constructor(props){
    super(props);
    this.state={templates: [], activity_id: ""};
    this.changeActivity = this.changeActivity.bind(this);
    this.addTemplateClick = this.addTemplateClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changeForm = this.changeForm.bind(this);
    this.submitTemplate = this.submitTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
  }

  componentDidMount(){
    request
      .get(this.props.appData.rootUrl+'/running_logs')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', buildHeaders())
      .then((results_str) => {
        // Creates a massaged array of user data
        let results = JSON.parse(results_str.text);
        this.setState({templates: results.running_logs});
      });
  }

  addTemplateClick(event){
    event.preventDefault();
    this.setState({modalIsOpen: true, newLog: {id: null, activity_id: this.props.userData.activities[0].id, distance: 0}})
  }

  closeModal(){
    this.setState({modalIsOpen: false})
  }
  changeActivity(activity_id){
    this.setState({activity_id: activity_id});
  }

  changeForm(ix, field, value){
    this.setState((oldState) => {
      let log = oldState.newLog;
      let newLog = update(log, {
        [field]: {$set: value}
      })
      return{
        newLog: newLog,
      }
    })
  }

  editTemplate(event, template_id){
    event.preventDefault();
    console.log(template_id)
    request
      .get(this.props.appData.rootUrl+'/running_logs/'+template_id)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', buildHeaders())
      .then((results_str) => {
        // Creates a massaged array of user data
        let results = JSON.parse(results_str.text);
        this.setState({modalIsOpen: true, newLog: results.running_log});
      });
  }

  submitTemplate(){
    const rl = this.state.newLog;
    const user_id= this.props.userData.id;
    let req;
    if(rl.id === null){
      req = request('POST', this.props.appData.rootUrl+'/users/'+user_id+'/running_logs/')
    }else{
      req = request('PUT', this.props.appData.rootUrl+'/users/'+user_id+'/running_logs/'+rl.id)
    }
    req
      .set('Content-Type', 'application/json')
      .set('Authorization', buildHeaders())
      .set('Accept', 'application/json')
      .send({running_log: rl})
      .then((results_str) => {
        let running_log = JSON.parse(results_str.text).running_log;
        this.setState((oldState) => {
          let templates;
          if(rl.id === null){
            templates = update(oldState.templates, {$push: [running_log]});
          }else{
            let old_rl_index = oldState.templates.findIndex((t) => {
              return t.id === rl.id;
            })
            templates = update(oldState.templates, {[old_rl_index]: {$set: running_log}});
          }
          return {templates: templates};
        })
        this.closeModal();
      })
      .catch((err) => {
        console.log(err.response)
        let error_json = JSON.parse(err.response.text).errors.running_logs;
        let error_array = Object.keys(error_json).map((key) => {
          return error_json[key].map((message) =>{
            return(key+": "+message);
          })
        })
        this.setState({error: error_array});
      })
  }

  render(){
    var activities = this.props.userData.activities.filter((activity) => {
      return activity.shared === true;
    });
    var templates = this.state.templates;
    if(this.state.activity_id !== ""){
      console.log(this.state.activity_id);
      templates = templates.filter((template) => {
        console.log(template.activity_id);
        return parseInt(template.activity_id) === parseInt(this.state.activity_id);
      })
    }
    return(
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          className={"biggerlogModal"}
          overlayClassName={"biggerlogModalOverlay"}
        >
          <RunningLogFormPartial
            pageUserData={this.props.user}
            runningLog={this.state.newLog}
            userData={this.props.userData}
            isCoach={true}
            updateRunningLog={this.changeForm}
            activities={this.props.userData.activities.filter((act) => {
              return act.shared
            })}
          />
          <span className="biggerlogButton colored" onClick={this.submitTemplate}>Save</span>
        </Modal>
        <StyledCoachDashboard>
          <div className="library">
            <span>
              Library
            </span>
            <div className="sublibrary">
              <span>Activity Types:</span>
              <ActivitySelect
                className="activity"
                activity_id={this.state.activity_id} 
                onChange={(event)=>{
                  event.preventDefault();
                  this.changeActivity(event.target.value);
                }}
                hasNull={true}
                activities={activities}
              />
              <div className="templates">
                <div className="addTemplate biggerlogButton" onClick={this.addTemplateClick}>New Template+</div>
                {
                  templates.map((template) => {
                    return(
                      <Template key={template.id} log_id={template.id}>
                        <TemplateHolder key={template.id} onClick={ (e) => this.editTemplate(e, template.id) }>
                          <RunningLog running_log={template} userData={this.props.userData}/>
                        </TemplateHolder>
                      </Template>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </StyledCoachDashboard>
      </>
    )
  }
}

function Template(props) {
  const [{ isDragging }, dragRef] = useDrag({
    item: { type: DragTypes.RUNNING_LOG, id: props.log_id},
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })
  return (
    <div ref={dragRef} style={{opacity: isDragging ? 0.5 : 1 }}>
      {props.children}
    </div>
  )
}
export default CoachDashboard;