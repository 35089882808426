import React, {useState, useCallback, useRef } from 'react'
import update from 'immutability-helper';
import { useDrag, useDrop } from 'react-dnd';
const DraggableList = ({items, finishMoveItem, setItems, RenderItem, item_type, style, ...props}) => {
  {
    const moveItem = useCallback(
      (dragIndex, hoverIndex) => {
        console.log("moveitem")
        const dragItem = items[dragIndex]
        setItems(
          update(items, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragItem],
            ],
          }),
        )
      },
      [items],
    )
    const finishMove = useCallback(
      () => {
        finishMoveItem(items)
      }
    )
    const renderItemShell = (item, index) => {
      return (
        <DraggableItem
          key={item.id}
          index={index}
          id={item.id}
          finishMove={finishMove}
          moveItem={moveItem}
          RenderItem={RenderItem}
          item_type={item_type}
          style={style}
          item={item}
          {...props}
        />
      )
    }
    return (
      <>
        {items.map((item, i) => renderItemShell(item, i))}
      </>
    )
  }
}
const DraggableItem = ({id, index, finishMove, moveItem, RenderItem, item_type, style, item, ...props}) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: item_type,
    drop(item, monitor) {
      finishMove();
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: item_type, id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <RenderItem 
      useRef={ref}
      style={{ ...style, opacity }}
      item={item}
      {...props}
    />
  )
}
export default DraggableList
