import React from 'react'
import {StyledSelectBox} from './styles/select_box_styles.js';

//props.items = {value: 'foo', name: 'bar'}
//props.selected_ids = [value1, value2, value3]
//props.addExtra = function addExtra(extra_id)
//props.removeExtra = function removeExtra(extra_id)
function SelectBox(props) {
  const clickLeft = (event) => {
    event.preventDefault();
    let target = event.target;
    if(event.target.nodeName === "SPAN"){
      target = event.target.parentElement;
    }
    const id = target.dataset.id;
    props.addExtra(id);
  }
  const clickRight = (event) => {
    event.preventDefault();
    let target = event.target;
    if(event.target.nodeName === "SPAN"){
      target = event.target.parentElement;
    }
    const id = target.dataset.id;
    props.removeExtra(id);
  }
  let items = props.items;
  let selected_ids = props.selected_ids;
  let listLeft = items.map(item => {
    if(selected_ids.findIndex(sel => parseInt(sel) === parseInt(item.id)) > -1){
      return null;
    }else{
      return (
        <li
          key={item.id}
          onClick={clickLeft}
          data-id={item.id}
        >
          <span
            
            
          >
            {item.name}
          </span>
        </li>
      );
    }
  });
  let listRight = items.map(item => {
    if(selected_ids.findIndex(sel => parseInt(sel) === parseInt(item.id)) > -1){
      return (
        <li
          key={item.id}
          onClick={clickRight}
          data-id={item.id}
        >
          <span
            
          >
            {item.name}
          </span>
        </li>
      );
    }else{
      return null;
    }
  });

  return(
    <StyledSelectBox>
      <ul className="left">
        {listLeft}
      </ul>

      <ul className="right">
        {listRight}
      </ul>
    </StyledSelectBox>
  )
}

export default SelectBox;