import React from 'react';
import {getActivityColor, getLogActivity, shadeHexColor} from './log_day.jsx';
import {convertUnit} from './helpers.jsx';
import {StyledRunningLog} from './styles/log_day_styles.js';
export function getPace(unit, distance, distance_unit, duration_s){
  if ((distance === null && duration_s === null) || distance === 0 || duration_s === 0){
    return null
  }
  let d = convertUnit(distance, distance_unit, unit);
  // if (unit === "mi"){
  //   if(distance_unit === "km"){
  //     d = distance/1.60934;
  //   }else if(distance_unit === "m"){
  //     d = distance/1609.34;
  //   }
  // }else if(unit === "km"){
  //   if(distance_unit === "mi"){
  //     d = distance*1.60934;
  //   }else if(distance_unit === "m"){
  //     d = distance/1000;
  //   }
  // }
  return secondsTimeSpanToMS(duration_s/d);
}

function secondsTimeSpanToMS(s){
  let [h1,m1,s1] = secondsTimeSpanToHMSArr(s);
  s1 = Math.round(s1);
  return m1 + ':' + (s1 < 10 ? '0' + s1 : s1);
} 

export function secondsTimeSpanToHMSArr(s){
  var h, m;
  h = Math.floor(s / 3600);
  s -= h * 3600;
  m = Math.floor(s / 60);
  s -= m * 60;
  return [h,m,s];
}
function secondsTimeSpanToHMS(s){//http://stackoverflow.com/questions/11792726/turn-seconds-into-hms-format-using-jquery
  let [h1,m1,s1] = secondsTimeSpanToHMSArr(s);
  return h1 + ':' + (m1 < 10 ? '0' + m1 : m1) + ':' + (s1 < 10 ? '0' + s1 : s1);
}
export const description = (rl) => {
  
  if (rl.substitute_description === null || rl.substitute_description.length === 0){
    let blurb = rl.distance + " " + rl.distance_unit;
    if(rl.duration_s !== null && rl.duration_s > 0){
      blurb += " for "+secondsTimeSpanToHMS(rl.duration_s)
      blurb += " at "+getPace(rl.distance_unit, rl.distance, rl.distance_unit, rl.duration_s)
      blurb += " min/" +rl.distance_unit 
    }
    return blurb;
  }else{
    return rl.substitute_description;
  }
}

function SmallLog(props) {
  return(
    <>
      {description(props.running_log)}
    </>
  )
}

//class RunningLog extends React.Component {
function RunningLog(props) {
  let rl = props.running_log;
  let background_color = getLogActivity(rl, props.userData.activities_hash).color_code;
  if(props.hovering){
    background_color =  shadeHexColor(background_color, -0.1);
  }
  return(
    <StyledRunningLog 
      key={rl.id} 
      backgroundColor={ background_color}
      isAssignment={rl.is_assignment}
    >
      <SmallLog running_log={rl}/>
    </StyledRunningLog>
  )
}

export default RunningLog;