import React from 'react';
import styled, {keyframes} from 'styled-components';
import * as variables from '../styles/variables.js';

const spinnerSize = 100;

const SvgKeyFrame = keyframes`
0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
`
const SpinnerKeyFrame = keyframes`
 0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }
  
  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  
  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
`
const SpinnerStyled = styled.svg`
  width: ${spinnerSize}px;
  height: ${spinnerSize}px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 ${spinnerSize} ${spinnerSize};
  opacity: 1;
  animation: 2s linear infinite ${SvgKeyFrame};
  max-width: 100px;
    
  circle {
    display: block;
    fill: transparent;
    stroke: #2f3d4c;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
    animation: 1.4s ease-in-out infinite both ${SpinnerKeyFrame};
  }
`
const Spinner = () => (
  <SpinnerStyled>
    <circle cx="50" cy="50" r="45"/>
  </SpinnerStyled>
)

export default Spinner;