import styled from 'styled-components';
import * as variables from './variables.js';

export const SelectorUlStyled = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  div.list-element{
    display: grid;
    grid-template-rows: 1fr;
    &.selected{
      position: relative;
      box-shadow: 0px 2px;
    }
  }
  div.remove-log{
    color: black;
    cursor: pointer;
    background-color: ${variables.LIGHT_DARK_RED};
    border-radius: 3px;
    padding: 5px;
    &:hover{
      background-color: ${variables.DARK_RED};
    }
  }
  div.add_log{
    color: black;
    cursor: pointer;
    color: ${variables.LIGHT_DARK_RED};
    background-color: ${variables.LIGHTEST_RED};
    &:hover{
      background-color: ${variables.VERY_LIGHT_RED};
      color: ${variables.DARK_RED};
    }
    height: 30.4px;
  }
`
export const DateHeaderStyled = styled.td`
  text-align: center;
`

export const WeekModalStyled = styled.table`
  width: 100%;
  padding-bottom: 60px;
  td.DayModal, td.weekSummary{
    vertical-align: top;
    border-right: 2px solid black;
    cursor: pointer;
    &:hover{
      box-shadow: 2px 4px #888888;
    }
    td.LogModal{
      border-radius: 6px;
    }
  }
  td.weekSummary{
    display: grid;
    grid-template-columns: 1fr;
  }
}
`

export const RunningLogFormStyled = styled.div`
  display:grid;
  grid-template-columns: [lhs] 1fr [center] 1fr [rhs];
  grid-template-rows: 1fr;
`

export const RunningLogFormLeft = styled.div`
  grid-template-columns: 120px 1fr;
  grid-template-rows: 45px 45px 45px 45px 45px 45px 45px 45px 1fr;
  grid-auto-rows: 30px;
  align-items: center;
  display: grid;
  justify-items: start;

  div.distance{
    input{
      width: 45px;
    }
  }

  .duration{
    input{
      width: 25px;
    }
  }

  div.pace{
    // grid-column: 2;
    // grid-row: 4;
    input{
      width: 60px;
    }
  }

`

export const RunningLogFormRight = styled.div`
  grid-template-columns: 1fr;
  grid-template-rows: 15px 120px 15px 1fr;
  display: grid;
  label{
    justify: center;
  }

  textarea{
    //height: 250px;  
  
  }
`

export const FormButtons = styled.div`
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  display: grid;
  width: 50%;
  input{
    text-align: center;
  }
`