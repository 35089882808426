import styled from 'styled-components';
import * as variables from './variables.js';

export const StyledCoachDashboard = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${variables.ACTIVE_MONTH_COLOR};
  padding: 5px 15px 0px 5px;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-radius: 0 5px 0 0;
  div.templates{
    padding: 5px;
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  padding-bottom: 20px;
  // div.sublibrary{
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  // }
  div.addTemplate{
    padding: 5px;
    border-radius: 15px;
    //place-self: center;
    background-color: ${variables.VERY_LIGHT_RED};
    &:hover{
      background-color: ${variables.LIGHTER_DARK_RED};
    }
  }
`

export const TemplateHolder = styled.div`
  cursor: move;
`